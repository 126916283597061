import React from 'react'
import ReactMarkdown from 'react-markdown'
import axios from 'axios'
import { Template } from '../types/types'

type Props = {
  template: Template
}

const TicketTemplatesDeleteModal: React.FC<Props> = ({ template }) => {
  const deleteTemplate = (): void => {
    const url = `/api/v1/media/${template.medium_id}/ticket_templates/${template.id}`
    const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''
    axios.delete(url, { headers: { 'X-CSRF-TOKEN': csrfToken, 'Content-Type': 'application/json' } }).then(() => {
      alert('施策テンプレートを削除しました')
      location.reload()
    })
  }

  return (
    <div
      className="modal fade bs-example-modal-lg"
      id="ticket_templates_delete_modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="Modal Title"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content p-2">
          <div className="modal-body">
            <h4 className="mb-5">こちらのテンプレートを削除しますか？</h4>
            <div className="mb-5">
              <h5 className="mb-2">タイトル</h5>
              <p>{template.title}</p>
            </div>
            <div className="mb-5">
              <h5 className="mb-2">概要</h5>
              <div className="border d-flex" style={{ minHeight: '200px' }}>
                <p className="border-right p-3 m-0" style={{ width: '50%', whiteSpace: 'pre-wrap' }}>
                  {template.description}
                </p>
                <div style={{ width: '50%' }}>
                  <ReactMarkdown className="p-3">{template.description}</ReactMarkdown>
                </div>
              </div>
            </div>
            <button className="btn btn-danger col-md-12" onClick={deleteTemplate}>
              削除
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TicketTemplatesDeleteModal
