import React, { useState, useEffect } from 'react'
import ArticleLists from './articleLists'
import TicketLists from './ticketLists'
import TagLists from './tagLists'
import { SearchTarget, ArticleSearchResult, TicketSearchResult, TagSearchResult } from '../types/headerSearch'

type Props = {
  searchTarget: SearchTarget
  results: (ArticleSearchResult | TicketSearchResult | TagSearchResult)[]
}

const SearchResultModal: React.FC<Props> = ({ results, searchTarget }): JSX.Element => {
  const [modalVisible, setModalVisible] = useState<boolean>(true)

  const ModalComponent = (): JSX.Element => {
    if (results.length === 0) return <></>
    if (!modalVisible) return <></>

    return (
      <div className="position-absolute bg-white" style={{ top: '140%', width: '150%', borderRadius: '8px', border: '0.5px solid gray' }}>
        <p
          className="p-3 m-0 text-left"
          style={{ color: 'black', width: '100%', fontSize: '14px', borderBottom: '1px solid darkgray', fontWeight: 'bold' }}
        >
          {searchTarget === 'articles' && '記事一覧'}
          {searchTarget === 'tickets' && '施策一覧'}
          {searchTarget === 'tags' && '記事タグ一覧'}
          {searchTarget === 'promotionTags' && 'プロモーションタグ一覧'}
          {RemoveComponent()}
        </p>
        <div style={{ height: '100%', maxHeight: '400px', overflow: 'scroll', display: 'flex', flexDirection: 'column' }}>
          {searchTarget === 'articles' && (
            <ArticleLists
              articles={results as ArticleSearchResult[]}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
            />
          )}
          {searchTarget === 'tickets' && (
            <TicketLists
              tickets={results as TicketSearchResult[]}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
            />
          )}
          {(searchTarget === 'tags' || searchTarget === 'promotionTags') && (
            <TagLists
              tags={results as TagSearchResult[]}
              searchTarget={searchTarget}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
            />
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    setModalVisible(true)
  }, [results])

  const handleMouseEnter = (evemt: React.MouseEvent): void => {
    // @ts-ignore
    evemt.target.style.backgroundColor = '#e9ecef'
  }

  const handleMouseLeave = (evemt: React.MouseEvent): void => {
    // @ts-ignore
    evemt.target.style.backgroundColor = ''
  }

  const RemoveComponent = (): JSX.Element => {
    return (
      <span
        className="position-absolute"
        style={{ display: 'inline-block', cursor: 'pointer', right: '16px' }}
        onClick={() => {
          setModalVisible(false)
        }}
      >
        X
      </span>
    )
  }

  return ModalComponent()
}

export default SearchResultModal
