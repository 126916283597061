import React from 'react'

type Props = {
  imageUrl: string
}

const ImageExpandModal: React.FC<Props> = ({ imageUrl }) => {
  return (
    <div
      className="modal fade bs-example-modal-lg"
      id="image_expand_modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="Modal Title"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <img src={imageUrl} className="w-100 mx-auto" style={{ height: '85vh', objectFit: 'contain' }} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default ImageExpandModal
