import axios from 'axios'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from 'chart.js'
import Loading from './common/Loading'
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler)

type Props = {
  articleId: string
  mediumId: string
}

type PromotionTagsResponse = {
  data: {
    monthly_values: { month: string; value: string }[]
    promotion_name: string
  }[]
}

const PromotionTagsGraph: React.FC<Props> = ({ articleId, mediumId }) => {
  const [fromDate, setFromDate] = useState<string>(moment().subtract(1, 'year').format('YYYY-MM-DD'))
  const [toDate, setToDate] = useState<string>(moment().format('YYYY-MM-DD'))
  const [selectedTab, setSelectedTab] = useState<'price' | 'count' | 'approved_rate' | 'average_unit_price'>('price')
  const [labels, setLabels] = useState<string[]>([])
  const [datasets, setDatasets] = useState<
    {
      label: string
      data: string[]
      borderColor: string
      yAxisID: string
    }[]
  >([])
  const [borderColors, setBorderColors] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const MONTHS_IN_YEAR = 12

  const options = {
    responsive: true,
    legend: {
      display: true,
    },
    scales: {
      y: {
        beginAtZero: true,
        type: 'linear',
        display: true,
        position: 'left',
      },
    },
  }

  const countOptions = {
    responsive: true,
    legend: {
      display: true,
    },
    scales: {
      y: {
        beginAtZero: true,
        type: 'linear',
        display: true,
        position: 'left',
        ticks: {
          stepSize: 1,
        },
      },
    },
  }

  const fetchPromotionTags = async (fromDateParam?: string): Promise<void> => {
    setIsLoading(true)
    const url = `/api/v1/media/${mediumId}/articles/${articleId}/promotion_tags`
    const queryParams = `?from_date=${fromDateParam || fromDate}&to_date=${toDate}&tab=${selectedTab}`

    await axios.get(url + queryParams).then((response: PromotionTagsResponse) => {
      if (!response.data.length) {
        setLabels([])
        setDatasets([])
      }
      if (response.data.length) {
        setLabels(response.data[0].monthly_values.map((monthly_value) => monthly_value.month))

        /*
        データの数が不定なので、APIで取得してからデータの数分のborderColorの色をランダムで決める。
        タブの切り替えでは色が変わらないようにstateに値を保存して、stateの値を優先する。
        */
        let borderColorsForGraph: string[] = []
        if (borderColors.length && borderColors.length === response.data.length) {
          borderColorsForGraph = borderColors
        } else {
          borderColorsForGraph = [...Array(response.data.length)].map(
            () => `rgb(${Math.round(Math.random() * 255)}, ${Math.round(Math.random() * 255)},${Math.round(Math.random() * 255)})`,
          )
          setBorderColors(borderColorsForGraph)
        }
        const datasets = response.data.map((data, index: number) => ({
          label: data.promotion_name,
          data: data.monthly_values.map((monthly_value) => monthly_value.value),
          borderColor: borderColorsForGraph[index],
          yAxisID: 'y',
        }))
        setDatasets(datasets)
      }
    })
    setIsLoading(false)
  }

  const fetchPromotionTagsByPeriod = (month: number): void => {
    const fromDate = moment().subtract(month, 'months').format('YYYY-MM-DD')
    setFromDate(fromDate)
    fetchPromotionTags(fromDate)
  }

  useEffect(() => {
    fetchPromotionTags()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedTab])

  return (
    <div className="mb-5">
      <div className="d-flex align-items-center mb-3">
        <input
          type="date"
          className="form-control col-md-3"
          value={fromDate}
          onChange={(e) => {
            setFromDate(e.target.value)
          }}
        />
        <span className="mr-3 ml-3">〜</span>
        <input
          type="date"
          className="form-control col-md-3 mr-3"
          value={toDate}
          onChange={(e) => {
            setToDate(e.target.value)
          }}
        />
        <button
          className="btn btn-primary ml-2 col-md-2"
          onClick={() => {
            fetchPromotionTags()
          }}
        >
          検索
        </button>
      </div>

      <div className="d-flex align-items-center mb-3">
        <button
          className="btn btn-outline-primary mr-2"
          onClick={() => {
            fetchPromotionTagsByPeriod(1)
          }}
        >
          過去1ヶ月間
        </button>
        <button
          className="btn btn-outline-primary mr-2"
          onClick={() => {
            fetchPromotionTagsByPeriod(3)
          }}
        >
          過去3ヶ月間
        </button>
        <button
          className="btn btn-outline-primary mr-2"
          onClick={() => {
            fetchPromotionTagsByPeriod(MONTHS_IN_YEAR)
          }}
        >
          過去1年間
        </button>
      </div>
      {!!labels.length ? (
        <>
          <ul className="nav nav-tabs">
            <li
              className={`nav-item nav-link ${selectedTab === 'price' ? ' active' : ''}`}
              style={{
                color: `${selectedTab === 'price' ? '#495057' : '#007bff'}`,
                cursor: `${selectedTab === 'price' ? 'initial' : 'pointer'}`,
              }}
              onClick={() => {
                setSelectedTab('price')
              }}
            >
              発生金額
            </li>
            <li
              className={`nav-item nav-link ${selectedTab === 'count' ? ' active' : ''}`}
              style={{
                color: `${selectedTab === 'count' ? '#495057' : '#007bff'}`,
                cursor: `${selectedTab === 'count' ? 'initial' : 'pointer'}`,
              }}
              onClick={() => {
                setSelectedTab('count')
              }}
            >
              発生件数
            </li>
            <li
              className={`nav-item nav-link ${selectedTab === 'approved_rate' ? ' active' : ''}`}
              style={{
                color: `${selectedTab === 'approved_rate' ? '#495057' : '#007bff'}`,
                cursor: `${selectedTab === 'approved_rate' ? 'initial' : 'pointer'}`,
              }}
              onClick={() => {
                setSelectedTab('approved_rate')
              }}
            >
              承認率
            </li>
            <li
              className={`nav-item nav-link ${selectedTab === 'average_unit_price' ? ' active' : ''}`}
              style={{
                color: `${selectedTab === 'average_unit_price' ? '#495057' : '#007bff'}`,
                cursor: `${selectedTab === 'average_unit_price' ? 'initial' : 'pointer'}`,
              }}
              onClick={() => {
                setSelectedTab('average_unit_price')
              }}
            >
              平均単価
            </li>
          </ul>
          <div className="position-relative">
            {isLoading && <Loading />}
            <Line
              data={{
                labels,
                datasets,
              }}
              // @ts-ignore
              options={selectedTab === 'count' ? countOptions : options}
            />
          </div>
        </>
      ) : (
        <p>データはありません</p>
      )}
    </div>
  )
}

export default PromotionTagsGraph
