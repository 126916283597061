import React, { useState, useEffect } from 'react'
import { WithContext as ReactTags } from 'react-tag-input'
import { PromotionTag, PromotionReactTag } from '../../types/promotions'

type Props = {
  promotionNames: string[]
  tagNames: PromotionTag[]
  defaultTags: PromotionTag[]
  isUpdatingTags: boolean
  onCilckUpdateTags: (tags: PromotionReactTag[]) => void
}

const KEYCODES = {
  enter: 13,
}

const TITLE_NUMS_DISPLAYED = 5

const PromotionsIndexTagsModal: React.FC<Props> = ({ promotionNames, defaultTags, tagNames, isUpdatingTags, onCilckUpdateTags }) => {
  const [tags, setTags] = useState<PromotionReactTag[]>([])

  const handleDelete = (i: number): void => {
    setTags(tags.filter((_, index) => index !== i))
  }

  const handleAddition = (tag: PromotionReactTag): void => {
    setTags([...tags, tag])
  }

  const changeTagPropertyIdToString = (tags: PromotionTag[]): PromotionReactTag[] => {
    return tags.map((tag) => ({ id: `${tag.id}`, name: tag.name }))
  }

  //  @ts-ignore
  const RemoveComponent = ({ onRemove }): JSX.Element => {
    return (
      <div onClick={onRemove} className="delete-btn">
        x
      </div>
    )
  }

  useEffect(() => {
    setTags(changeTagPropertyIdToString(defaultTags))
  }, [defaultTags])

  return (
    <div
      className="modal fade bs-example-modal-lg"
      id="promotions_index_tags_modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="Modal Title"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md tag-form p-4">
        <div className="modal-content p-4">
          <div className="modal-header" style={{ display: 'block' }}>
            {promotionNames.slice(0, TITLE_NUMS_DISPLAYED).map((title, index) => (
              <h4 className="modal-title" key={index}>
                {title}
              </h4>
            ))}
            <p className="text-end">
              {promotionNames.length > 5 ? '...' : ''}（全{promotionNames.length}件）
            </p>
          </div>
          <div className="modal-body mt-4">
            <div className="tag-form-content">
              <div className="mr-4">タグ名</div>
              <div className="input-box">
                {/*  @ts-ignore */}
                <ReactTags
                  tags={tags}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  inputFieldPosition="top"
                  autocomplete={false}
                  placeholder="プロモーションタグを入力しENTERで追加"
                  delimiters={[KEYCODES.enter]}
                  removeComponent={RemoveComponent}
                  suggestions={changeTagPropertyIdToString(tagNames)}
                  labelField={'name'}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {isUpdatingTags ? (
              <div>
                <span className="spinner-border mr-2" role="status"></span>
                登録中...
              </div>
            ) : (
              <button
                className="btn btn-primary add"
                onClick={() => {
                  onCilckUpdateTags(tags)
                }}
              >
                タグを更新する
              </button>
            )}
            {/* jsでmodal closeできないのでclose用隠しボタンを設置してクリックする */}
            <button type="button" data-dismiss="modal" className="d-none" id="close_promotions_index_tags_modal_button" />
          </div>
        </div>
      </div>
    </div>
  )
}
export default PromotionsIndexTagsModal
