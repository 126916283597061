import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { CurrentMedium } from '../../types/common'
import { Promotion, PromotionTag, PromotionReactTag, HasPromotionTagsFilterValue } from '../../types/promotions'
import PromotionsIndexTagsModal from './index_promotionTagsModal'
import PromotionsIndexFilter from './index_filter'
import PromotionsIndexPromotionList from './index_promotionList'

type Props = {
  currentMedium: CurrentMedium
  tagNames: PromotionTag[]
}

const PromotionsIndex: React.FC<Props> = ({ currentMedium, tagNames }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [promotionNameFilterValue, setPromotionNameFilterValue] = useState<string>('')
  const [hasPromotionTagsFilterValue, setHasPromotionTagsFilterValue] = useState<HasPromotionTagsFilterValue>('all')
  const [defaultPromotions, setDefaultPromotions] = useState<Promotion[]>([])
  const [promotions, setPromotions] = useState<Promotion[]>([])
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false)
  const [checkedPromotions, setCheckedPromotions] = useState<Promotion[]>([])
  const [updatingPromotionIds, setUpdatingPromotionIds] = useState<number[]>([])
  const [updatingPromotionNames, setUpdatingPromotionNames] = useState<string[]>([])
  const [updatingPromotionDefaultTags, setUpdatingPromotionDefaultTags] = useState<PromotionTag[]>([])
  const [isUpdatingTags, setIsUpdatingTags] = useState<boolean>(false)

  const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''

  const fetchPromotions = async (): Promise<void> => {
    setIsLoading(true)
    const url = `/api/v1/media/${currentMedium.id}/promotions`
    await axios
      .get(url)
      .then((response) => {
        const promotions = JSON.parse(response.data.promotions)
        setPromotions(promotions)
        setDefaultPromotions(promotions)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const filterPromotionsByHasPromotionTags = (targetPromotions: Promotion[]): Promotion[] => {
    // FIXME: タグがない場合に返ってくるのが空配列じゃないなら修正する
    if (hasPromotionTagsFilterValue === 'all') {
      return targetPromotions
    }
    if (hasPromotionTagsFilterValue === 'hasPromotionTags') {
      return targetPromotions.filter((promotion) => promotion.tags.length !== 0)
    }
    if (hasPromotionTagsFilterValue === 'notHasPromotionTags') {
      return targetPromotions.filter((promotion) => promotion.tags.length === 0)
    }
    return []
  }

  const filterPromotions = (): void => {
    if (!promotionNameFilterValue) {
      setPromotions(filterPromotionsByHasPromotionTags(defaultPromotions))
      return
    }
    const resultsFilteredByPromotionName = defaultPromotions.filter((promotion) => promotion.name.includes(promotionNameFilterValue))
    setPromotions(filterPromotionsByHasPromotionTags(resultsFilteredByPromotionName))
  }

  const toggleCheckPromotion = (targetPromotion: Promotion): void => {
    if (checkedPromotions.includes(targetPromotion)) {
      setCheckedPromotions([...checkedPromotions.filter((promotion) => promotion !== targetPromotion)])
    } else {
      setCheckedPromotions([...checkedPromotions, targetPromotion])
    }
  }

  const toggleCheckAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      setCheckedPromotions(promotions)
      setIsCheckedAll(true)
    } else {
      setCheckedPromotions([])
      setIsCheckedAll(false)
    }
  }

  const alertIfNotCheckedPromotions = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (!checkedPromotions.length) {
      alert('プロモーションが選択されていません')
      e.stopPropagation()
      return
    }
  }

  const setDataForEditMulti = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    alertIfNotCheckedPromotions(e)
    setUpdatingPromotionIds(checkedPromotions.map((promotion) => promotion.id))
    setUpdatingPromotionNames(checkedPromotions.map((promotion) => promotion.name))
    if (checkedPromotions.length === 1) {
      setUpdatingPromotionDefaultTags(checkedPromotions[0].tags)
    } else {
      setUpdatingPromotionDefaultTags([])
    }
  }

  const setDataForEditOne = (promotion: Promotion): void => {
    setUpdatingPromotionIds([promotion.id])
    setUpdatingPromotionNames([promotion.name])
    setUpdatingPromotionDefaultTags(promotion.tags)
  }

  const changeTagPropertyIdToNumber = (tags: PromotionReactTag[]): PromotionTag[] => {
    return tags.map((tag) => ({ id: Number(tag.id), name: tag.name }))
  }

  const updateTags = (tags: PromotionReactTag[]): void => {
    const tagsForVariables = changeTagPropertyIdToNumber(tags)
    const tagNames = tagsForVariables.map((tag) => tag.name)

    setIsUpdatingTags(true)
    const url = `/api/v1/media/${currentMedium.id}/promotion_tags`
    const params = {
      promotion_ids: updatingPromotionIds,
      tag_names: tagNames,
    }
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
      body: JSON.stringify(params),
    }).then((response) => {
      if (response.ok) {
        alert('タグを更新しました')
        location.reload()
      } else {
        alert('タグの更新に失敗しました')
        setIsUpdatingTags(false)
      }
    })
  }

  const closeModal = (): void => {
    // jsでmodal closeできないのでclose用隠しボタンを設置してクリックする
    document.getElementById('close_promotions_index_tags_modal_button')?.click()
  }

  const onCilckUpdateTags = (tags: PromotionReactTag[]): void => {
    if (!tags.length) {
      alert('タグが入力されていません。')
      return
    }

    updateTags(tags)
    closeModal()
  }

  useEffect(() => {
    filterPromotions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotionNameFilterValue, hasPromotionTagsFilterValue, defaultPromotions])

  useEffect(() => {
    fetchPromotions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <h3 className="mb-5">プロモーション設定</h3>

      <PromotionsIndexFilter
        promotionNameFilterValue={promotionNameFilterValue}
        hasPromotionTagsFilterValue={hasPromotionTagsFilterValue}
        setPromotionNameFilterValue={setPromotionNameFilterValue}
        setHasPromotionTagsFilterValue={setHasPromotionTagsFilterValue}
      />

      <button
        className="btn btn-success float-right mb-3"
        onClick={(e) => setDataForEditMulti(e)}
        data-target="#promotions_index_tags_modal"
        data-toggle="modal"
      >
        タグを一括編集
      </button>
      <PromotionsIndexPromotionList
        promotions={promotions}
        checkedPromotions={checkedPromotions}
        currentMedium={currentMedium}
        isCheckedAll={isCheckedAll}
        isLoading={isLoading}
        toggleCheckAll={toggleCheckAll}
        toggleCheckPromotion={toggleCheckPromotion}
        setDataForEditOne={setDataForEditOne}
      />

      <PromotionsIndexTagsModal
        promotionNames={updatingPromotionNames}
        tagNames={tagNames}
        defaultTags={updatingPromotionDefaultTags}
        isUpdatingTags={isUpdatingTags}
        onCilckUpdateTags={onCilckUpdateTags}
      />
    </>
  )
}

export default PromotionsIndex
