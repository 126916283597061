import React from 'react'
import { Template } from '../types/types'

type Props = {
  ticketTemplates: Template[]
  isOnlyForUseTemplate?: boolean
  currentMedium?: {
    id: string
  }
  openDeleteModal?: (template: Template) => void
  setTemplate?: (description: Template['description']) => void
}

const TicketTemplateList: React.FC<Props> = ({
  ticketTemplates,
  isOnlyForUseTemplate = false,
  currentMedium,
  openDeleteModal = () => {},
  setTemplate = () => {},
}) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">施策テンプレート名</th>
          <th scope="col"></th>
          {!isOnlyForUseTemplate && <th scope="col"></th>}
        </tr>
      </thead>
      <tbody>
        {ticketTemplates.map((template) => (
          <tr key={template.id}>
            {!!currentMedium ? (
              <td className="col-md-6 align-middle p-0">
                <a href={`/media/${currentMedium.id}/ticket_templates/${template.id}`}>{template.title}</a>
              </td>
            ) : (
              <td className="col-md-6">{template.title}</td>
            )}
            {isOnlyForUseTemplate ? (
              <td className="col-md-2">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setTemplate(template.description)
                  }}
                  data-dismiss="modal"
                >
                  利用する
                </button>
              </td>
            ) : (
              <>
                <td className="col-md-1" />
                <td className="col-md-1">
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      openDeleteModal(template)
                    }}
                    data-toggle="modal"
                    data-target="#ticket_templates_delete_modal"
                  >
                    削除
                  </button>
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
export default TicketTemplateList
