import React from 'react'
import axios from 'axios'
import moment from 'moment'
import { MonthlyGraphMonthlyData, MonthlyAndDailyGraphDatasets } from '../types/chart'

type Props = {
  setChartDataForPromotionTagGraph?: React.Dispatch<
    React.SetStateAction<{
      monthlyGraph: MonthlyGraphMonthlyData
    }>
  >
  setDataForArticleGraph?: React.Dispatch<
    React.SetStateAction<{
      monthlyData: MonthlyAndDailyGraphDatasets
      dailyData: MonthlyAndDailyGraphDatasets
    }>
  >
  setLabelsForGraph?: React.Dispatch<
    React.SetStateAction<{
      month: string[]
      date: string[]
    }>
  >
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setFromDate: React.Dispatch<React.SetStateAction<string>>
  setToDate: React.Dispatch<React.SetStateAction<string>>
  paramsData: { [key: string]: string }
  location: string
  fromDate: string
  toDate: string
  mediumId: number
}

const Search: React.FC<Props> = ({
  setChartDataForPromotionTagGraph = () => {},
  setDataForArticleGraph = () => {},
  setLabelsForGraph = () => {},
  paramsData,
  location,
  setIsLoading,
  setFromDate,
  setToDate,
  fromDate,
  toDate,
  mediumId
}) => {
  const MONTHS_IN_YEAR = 12
  
  const search = async (fromDateParam?: string): Promise<void> => {
    const params = {
      from_date: fromDateParam || fromDate,
      to_date: toDate,
      ...paramsData,
    }
    setIsLoading(true)
    await axios({
      method: 'get',
      url: `/api/v1/media/${mediumId}/${location}/search`,
      params,
    })
      .then((results) => {
        const { monthlyGraph, dailyGraph } = results.data
        if (dailyGraph) {
          setLabelsForGraph({
            month: monthlyGraph.labels,
            date: dailyGraph.labels,
          })

          setDataForArticleGraph({
            monthlyData: monthlyGraph.datasets,
            dailyData: dailyGraph.datasets,
          })
        } else {
          setChartDataForPromotionTagGraph({ monthlyGraph: monthlyGraph })
        }
        setIsLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false)
      })
  }

  const searchByPeriod = (month: number): void => {
    const fromDate = moment().subtract(month, 'months').format('YYYY-MM-DD')
    setFromDate(fromDate)
    search(fromDate)
  }

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <span className="mr-5">期間</span>
        <input
          type="date"
          className="form-control col-md-3"
          value={fromDate}
          onChange={(e) => {
            setFromDate(e.target.value)
          }}
        />
        <span className="mr-3 ml-3">〜</span>
        <input
          type="date"
          className="form-control col-md-3 mr-3"
          value={toDate}
          onChange={(e) => {
            setToDate(e.target.value)
          }}
        />
        <button
          className="btn btn-primary ml-2 col-md-2"
          onClick={() => {
            search()
          }}
        >
          検索
        </button>
      </div>

      <div className="d-flex align-items-center mb-3">
        <button
          className="btn btn-outline-primary mr-2"
          onClick={() => {
            searchByPeriod(1)
          }}
        >
          過去1ヶ月間
        </button>
        <button
          className="btn btn-outline-primary mr-2"
          onClick={() => {
            searchByPeriod(3)
          }}
        >
          過去3ヶ月間
        </button>
        <button
          className="btn btn-outline-primary mr-2"
          onClick={() => {
            searchByPeriod(6)
          }}
        >
          過去6ヶ月間
        </button>
        <button
          className="btn btn-outline-primary mr-2"
          onClick={() => {
            searchByPeriod(MONTHS_IN_YEAR)
          }}
        >
          過去1年間
        </button>
      </div>
    </>
  )
}

export default Search
