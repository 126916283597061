import React, { useState, useEffect } from 'react'
import TicketTemplateList from './TicketTemplateList'
import TicketTemplatesSearchInput from './TicketTemplatesSearchInput'
import { Template } from '../types/types'

type Props = {
  defaultTicketTemplates: Template[]
  setTemplate: (description: Template['description']) => void
}

const TicketTemplatesUseModal: React.FC<Props> = ({ defaultTicketTemplates, setTemplate }) => {
  const [ticketTemplates, setTicketTemplates] = useState<Template[]>([])

  const filterTemplates = (searchInput: string): void => {
    if (!searchInput) {
      setTicketTemplates(defaultTicketTemplates)
      return
    }
    const results = defaultTicketTemplates.filter((template) => template.title.includes(searchInput))
    setTicketTemplates(results)
  }

  useEffect(() => {
    setTicketTemplates(defaultTicketTemplates)
  }, [defaultTicketTemplates])

  return (
    <div
      className="modal fade bs-example-modal-lg"
      id="ticket_templates_use_modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="Modal Title"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content p-2">
          <div className="modal-body">
            <h3 className="mb-5">施策テンプレート一覧</h3>
            <TicketTemplatesSearchInput filterTemplates={filterTemplates} />
            <TicketTemplateList ticketTemplates={ticketTemplates} setTemplate={setTemplate} isOnlyForUseTemplate />
          </div>
        </div>
      </div>
    </div>
  )
}
export default TicketTemplatesUseModal
