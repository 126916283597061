import React from 'react'

type Props = {
  name: string
  message: string
}

const Alert: React.FC<Props> = ({ name, message }) => {
  const className = `alert alert-${name} mb-4 text-center`
  return (
    <div className={className} role="alert">
      {message}
    </div>
  )
}

export default Alert
