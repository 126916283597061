import React from 'react'
import Alert from './Alert'

type Props = {
  flash: [string, string][]
}

function bootstrapAlertClass(key: string): string {
  switch (key) {
    case 'alert':
      return 'warning'
    case 'error':
      return 'danger'
    case 'notice':
      return 'success'
    default:
      return key
  }
}

const Flash: React.FC<Props> = ({ flash }) => {
  const alertItems = flash.map((item, idx) => {
    const [key, message] = item
    return <Alert key={idx} name={bootstrapAlertClass(key)} message={message} />
  })

  return <div>{alertItems}</div>
}

export default Flash
