import React, { useEffect, useState, useRef, useCallback } from 'react'
import axios from 'axios'
import { useDebounce } from '../../hooks/useDebounce'
import SearchResultModal from './searchResultModal'
import { SearchTarget, ArticleSearchResult, TicketSearchResult, TagSearchResult } from '../types/headerSearch'
import Loading from '../common/Loading'

type Props = {
  mediumId: number
}

const SearchForm: React.FC<Props> = ({ mediumId }): JSX.Element => {
  const [inputText, setInputText] = useState<string>('')
  const [searchTarget, setSearchTarget] = useState<SearchTarget>('articles')
  const [searchResult, setSearchResult] = useState<(ArticleSearchResult | TicketSearchResult | TagSearchResult)[]>([])
  const debouncedInputText = useDebounce(inputText, 500)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const searchInputRef = useRef<HTMLInputElement>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => setInputText(event.target.value)

  useEffect(() => {
    if (debouncedInputText.length == 0) {
      setSearchResult([])
      return
    }
    if (searchTarget === 'articles') {
      searchArticles()
    }
    if (searchTarget === 'promotionTags') {
      searchPromotionTags()
    }
    if (searchTarget === 'tickets') {
      searchTickets()
    }
    if (searchTarget === 'tags') {
      searchTags()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputText])

  const searchArticles = async (): Promise<void> => {
    const url = `/api/v1/media/${mediumId}/articles/search_articles_by_query`
    const params = {
      query: debouncedInputText,
    }
    setIsLoading(true)

    await axios({
      method: 'get',
      url: url,
      params,
    })
      .then((results) => {
        setSearchResult(results.data.articles)
        setIsLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false)
      })
  }

  const searchPromotionTags = async (): Promise<void> => {
    const url = `/api/v1/media/${mediumId}/promotion_tags`
    const params = {
      query: debouncedInputText,
    }
    setIsLoading(true)

    await axios({
      method: 'get',
      url: url,
      params,
    })
      .then((results) => {
        setSearchResult(JSON.parse(results.data.promotion_tags))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const searchTickets = async (): Promise<void> => {
    const params = {
      query: debouncedInputText,
    }
    setIsLoading(true)

    await axios({
      method: 'get',
      url: `/api/v1/media/${mediumId}/tickets/search`,
      params,
    })
      .then((results) => {
        setSearchResult(JSON.parse(results.data.tickets))
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const searchTags = async (): Promise<void> => {
    const params = { query: debouncedInputText }
    const url = `/api/v1/media/${mediumId}/tags`
    setIsLoading(true)

    await axios({
      method: 'get',
      url: url,
      params,
    })
      .then((results) => {
        setSearchResult(JSON.parse(results.data.tags))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleSearchTarget = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setInputText('')
    setSearchResult([])
    setSearchTarget(e.target.value as SearchTarget)
  }

  const focusSearchInputWithCtrlP = useCallback((e: KeyboardEvent) => {
    if (!searchInputRef || !searchInputRef.current) return
    // MacならCommandキー、WindowsならCtrlキー
    if (((e.ctrlKey && !e.metaKey) || (!e.ctrlKey && e.metaKey)) && e.code === 'KeyP') {
      e.preventDefault()
      searchInputRef.current.focus()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', focusSearchInputWithCtrlP)
    return () => window.removeEventListener('keydown', focusSearchInputWithCtrlP)
  }, [focusSearchInputWithCtrlP])

  const FormElement = (): JSX.Element => {
    return (
      <>
        <div className="d-flex align-items-center position-relative">
          {isLoading && <Loading />}
          <div className="form-inline fr mb-0 ">
            <select className="form-control" value={searchTarget} onChange={(e) => handleSearchTarget(e)}>
              <option value="articles">記事</option>
              <option value="tickets">施策</option>
              <option value="tags">記事タグ</option>
              <option value="promotionTags">プロモーションタグ</option>
            </select>
          </div>
          <div className="form-inline fr mb-0 ">
            <input
              className="form-control mr-2 w-full"
              placeholder="キーワードを指定してください"
              type="text"
              value={inputText}
              onChange={handleChange}
              ref={searchInputRef}
            />
          </div>

          <SearchResultModal results={searchResult} searchTarget={searchTarget} />
        </div>
      </>
    )
  }

  return FormElement()
}

export default SearchForm
