import React, { useState, useEffect } from 'react'
import axios from 'axios'
import humps from 'humps'
import { Bar, Chart } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
  TooltipModel,
} from 'chart.js'
import Search from './search'
import moment from 'moment'
import { MonthlyAndDailyGraphData, MonthlyAndDailyGraphDatasets, KeyProperties } from '../types/chart'
import Loading from '../common/Loading'

type Props = {
  monthlyGraph: MonthlyAndDailyGraphData
  dailyGraph: MonthlyAndDailyGraphData
  paramsData: { [key: string]: string }
  articleId?: number
  location: string
  mediumId: number
  defaultFromDate?: string
  defaultToDate?: string
}

type Ticket = {
  id: number
  title: string
  validateStartAt: string
  user: {
    username: string
    thumbnail?: {
      url: string
    }
  }
}

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler)

let hasInit = false

const MonthlyAndDaily: React.FC<Props> = ({
  monthlyGraph,
  dailyGraph,
  paramsData,
  articleId,
  location,
  mediumId,
  defaultFromDate,
  defaultToDate,
}) => {
  const [fromDate, setFromDate] = useState<string>(
    defaultFromDate ? moment(defaultFromDate).format('YYYY-MM-DD') : moment().subtract(1, 'year').format('YYYY-MM-DD'),
  )
  const [toDate, setToDate] = useState<string>(defaultToDate ? moment(defaultToDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'))

  const [labelsForGraph, setLabelsForGraph] = useState<{
    month: string[]
    date: string[]
  }>({
    month: monthlyGraph.labels,
    date: dailyGraph.labels,
  })

  const [dataForGraph, setDataForGraph] = useState<{
    monthlyData: MonthlyAndDailyGraphDatasets
    dailyData: MonthlyAndDailyGraphDatasets
  }>({
    monthlyData: monthlyGraph.datasets,
    dailyData: dailyGraph.datasets,
  })

  const [ticketsData, setTicketsData] = useState<Ticket[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const keys = Object.entries(monthlyGraph.keys).map((key) => key[0]) as KeyProperties[]

  const RANK_COLOR = '#e87019'
  const RANK_WITH_TICKETS_COLOR = '#61aca2'

  useEffect(() => {
    const fetchTickets = async (): Promise<void> => {
      const API_ENDPOINT = `/api/v1/articles/${articleId}/tickets`
      const response = await axios.get(API_ENDPOINT)
      setTicketsData(humps.camelizeKeys(JSON.parse(response.data.tickets)) as Ticket[])
    }

    if (!hasInit && articleId) {
      hasInit = true
      fetchTickets()
    }
  }, [articleId])

  const scalesForLeftAxis = {
    type: 'linear',
    display: true,
    position: 'left',
  }
  const scalesForRightAxis = {
    type: 'linear',
    display: true,
    position: 'right',
    grid: {
      drawOnChartArea: false,
    },
  }

  const getOrCreateTooltip = (chart: ChartJS): HTMLDivElement => {
    let tooltipEl = chart.canvas.parentNode?.querySelector('div')

    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.classList.add('chart-custom-tooltip')

      const section = document.createElement('section')

      tooltipEl.appendChild(section)
      chart.canvas.parentNode?.appendChild(tooltipEl)
    }

    return tooltipEl
  }

  const externalTooltipHandler = async (context: { chart: ChartJS; tooltip: TooltipModel<'line'> }): Promise<void> => {
    // Tooltip Element
    const { chart, tooltip } = context
    const tooltipEl = getOrCreateTooltip(chart)

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || []
      const bodyLines = tooltip.body.map((b) => b.lines)

      const header = document.createElement('header')

      titleLines.forEach((title) => {
        const text = document.createTextNode(title)
        const p = document.createElement('p')
        p.classList.add('title')

        p.appendChild(text)
        header.appendChild(p)
      })

      const content = document.createElement('div')
      content.classList.add('content')

      bodyLines.forEach((body, i) => {
        const contentInner = document.createElement('div')
        contentInner.classList.add('inner')

        const defaultContent = document.createElement('p')
        defaultContent.classList.add('default-content')

        const span = document.createElement('span')
        const colors = tooltip.labelColors[i]
        span.style.background = colors.backgroundColor as string
        span.style.borderColor = colors.borderColor as string
        span.classList.add('color-icon')

        const text = document.createTextNode(body[0])

        defaultContent.appendChild(span)
        defaultContent.appendChild(text)
        contentInner.appendChild(defaultContent)
        content.appendChild(contentInner)

        const targetTicketsData = ticketsData.filter(
          (ticket: { validateStartAt: moment.MomentInput }) => moment(ticket.validateStartAt).format('YYYY/MM/DD') === titleLines[0],
        )
        if (!targetTicketsData.length) return

        const tickets = document.createElement('div')
        tickets.classList.add('tickets')

        targetTicketsData.forEach((ticket: { id: number; title: string; user: { thumbnail?: { url: string }; username: string } }) => {
          const ticketEl = document.createElement('div')
          ticketEl.classList.add('ticket')

          const titleLink = document.createElement('a')
          titleLink.href = `/media/51/tickets/${ticket.id}`
          titleLink.innerText = ticket.title
          titleLink.target = '_blank'
          titleLink.classList.add('ticket-title')

          const user = document.createElement('div')
          user.classList.add('user')

          const userIcon = document.createElement('img')
          userIcon.classList.add('user-icon')
          userIcon.src = ticket.user.thumbnail?.url || ''
          userIcon.alt = ticket.user.username

          const userName = document.createElement('p')
          userName.classList.add('user-name')
          const userNameText = document.createTextNode(ticket.user.username)
          userName.appendChild(userNameText)

          user.appendChild(userIcon)
          user.appendChild(userName)
          ticketEl.appendChild(titleLink)
          ticketEl.appendChild(user)
          if (tickets) {
            tickets.appendChild(ticketEl)
          }
        })
        contentInner.appendChild(tickets)
      })

      const section = tooltipEl.querySelector('section') as HTMLElement

      // Remove old children
      if (section) {
        while (section.firstChild) {
          section.firstChild.remove()
        }
      }

      // Add new children
      section.appendChild(header)
      section.appendChild(content)
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1'
    tooltipEl.style.left = positionX + tooltip.caretX + 'px'
    tooltipEl.style.top = positionY + tooltip.caretY + 'px'
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
    // tooltipにhoverしている間はtooltipが消えないように
    tooltipEl.addEventListener('mouseenter', () => {
      console.log('mouseenter!!!')
      tooltipEl.style.opacity = '1'
    })
    tooltipEl.addEventListener('mouseleave', () => {
      console.log('mouseleave!!!')
      tooltipEl.style.opacity = '1'
    })
  }

  const optionsForSingleBarChart = {
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      yLeftBar: {
        ...scalesForLeftAxis,
        ticks: {
          beginAtZero: true,
          callback: function (value: string, _: number, values: { value: number }[]) {
            if (values[0].value == 0 && values[values.length - 1].value == 1) return
            return `${value}円`
          },
        },
      },
      yRightLine: {
        ...scalesForRightAxis,
        ticks: {
          beginAtZero: true,
          callback: function (value: string, _: number, values: { value: number }[]) {
            if (values[0].value == 0 && values[values.length - 1].value == 1) return
            return `${value}%`
          },
        },
      },
      yRightBar: {
        ...scalesForRightAxis,
        ticks: {
          beginAtZero: true,
          callback: function (value: string, _: number, values: { value: number }[]) {
            if (values[0].value == 0 && values[values.length - 1].value == 1) return
            return `${value}件`
          },
        },
      },
      yRightAveragePriceBar: {
        ...scalesForRightAxis,
        ticks: {
          beginAtZero: true,
          callback: function (value: string, _: number, values: { value: number }[]) {
            if (values[0].value == 0 && values[values.length - 1].value == 1) return
            return `${value}円`
          },
          title: {
            text: '（ 平均単価 ）',
            display: true,
          },
        },
      },

      yLeftLineReverse: {
        ...scalesForRightAxis,
        beginAtZero: true,
        min: 1,
        stepSize: 1,
        reverse: true,
        ticks: {
          stepSize: 1,
          beginAtZero: true,
          callback: function (value: string, _: number, values: { value: number }[]) {
            if (values.length == 2) return
            return `${value}位`
          },
        },
      },
    },
  }
  const optionsForLineChart = {
    ...optionsForSingleBarChart,
    interaction: {
      intersect: false,
    },
    plugins: {
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler,
      },
    },
  }

  const DailyChart = (key: KeyProperties): JSX.Element => {
    if (key == 'rank') {
      const labels = labelsForGraph.date
      // ticketsDataの日付から施策を持っている日付のlabelのindexリストを取得
      const indexWithTicketsList = ticketsData
        .map((ticket) => labels.flatMap((label, index) => (moment(label).isSame(moment(ticket.validateStartAt)) ? index : [])))
        .flat()

      // datasetsから検索順位のdatasetsを取り出して、取得したindexリストに含まれていれば違う色を設定
      const defaultDatasets = dataForGraph.dailyData[key]
      const rankDatasetIndex = defaultDatasets.findIndex((dataset) => dataset.borderColor === RANK_COLOR)
      const newRankDataset = { ...defaultDatasets[rankDatasetIndex] }
      // @ts-ignore
      newRankDataset.backgroundColor = (context: { dataIndex: number }) => {
        const index = context.dataIndex
        return indexWithTicketsList.includes(index) ? RANK_WITH_TICKETS_COLOR : RANK_COLOR
      }
      // @ts-ignore
      newRankDataset.borderColor = (context: { dataIndex: number }) => {
        const index = context.dataIndex
        return indexWithTicketsList.includes(index) ? RANK_WITH_TICKETS_COLOR : RANK_COLOR
      }

      const newDatasets = [...defaultDatasets].filter((_, index) => index !== rankDatasetIndex)
      newDatasets.push(newRankDataset)

      return (
        <Chart
          data={{ labels, datasets: newDatasets }}
          type="line"
          // @ts-ignore
          options={optionsForLineChart}
          height={160}
        />
      )
    } else {
      return (
        <Bar
          data={{
            labels: labelsForGraph.date,
            datasets: dataForGraph.dailyData[key],
          }}
          // @ts-ignore
          options={optionsForSingleBarChart}
          // @ts-ignore
          height={220}
        />
      )
    }
  }

  return (
    <>
      <Search
        setDataForArticleGraph={setDataForGraph}
        setLabelsForGraph={setLabelsForGraph}
        paramsData={paramsData}
        location={location}
        setIsLoading={setIsLoading}
        setFromDate={setFromDate}
        setToDate={setToDate}
        fromDate={fromDate}
        toDate={toDate}
        mediumId={mediumId}
      />
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {keys.map((key: KeyProperties, index: number) => {
          return (
            <li key={key} className="nav-item">
              <a
                className={`${key} nav-link ${index == 0 ? 'active' : ''}`}
                aria-selected={index == 0 ? true : false}
                data-toggle="tab"
                href={`#${key}`}
                role="tabpanel"
              >
                {monthlyGraph.keys[key]}
              </a>
            </li>
          )
        })}
      </ul>

      <div id="myTabContent" className="tab-content mb-4">
        {keys.map((key, index) => {
          return (
            <div key={key} id={`${key}`} className={`tab-pane fade show false ${index == 0 ? 'active' : ''}`} role="tabpanel">
              <div className="row" style={{ width: '107%' }}>
                {dataForGraph.monthlyData[key] && (
                  <div className={`${dataForGraph.dailyData[key] ? 'col-md-6' : 'col-md-12'} position-relative p-0`}>
                    {isLoading && <Loading />}
                    <Bar
                      data={{
                        labels: labelsForGraph.month,
                        datasets: dataForGraph.monthlyData[key],
                      }}
                      // @ts-ignore
                      options={optionsForSingleBarChart}
                      // @ts-ignore
                      height={220}
                    />
                  </div>
                )}
                {dataForGraph.dailyData[key] && (
                  <div className={`${dataForGraph.monthlyData[key] ? 'col-md-6' : 'col-md-12'} position-relative p-0`}>
                    {isLoading && <Loading />}
                    {DailyChart(key)}
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default MonthlyAndDaily
