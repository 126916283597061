import axios from 'axios'
import React, { useState } from 'react'
import Alert from './common/Alert'
import { TicketAssignedUser, TicketAssignedUserInput, TicketNewState } from './types/ticket'

type Props = {
  initialAssignedUserInput: TicketAssignedUserInput
  assignedUsers: TicketAssignedUser[]
  usernames: string[]
  mediumId: number
  ticketId?: number
  ticketState: TicketNewState
  setAssignedUsers: React.Dispatch<React.SetStateAction<TicketAssignedUser[]>>
  setIsOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>
  setSavedAssignedUsers: React.Dispatch<React.SetStateAction<TicketAssignedUser[]>>
  setTicketState: React.Dispatch<React.SetStateAction<TicketNewState>>
}

type AlertMessage = {
  type: 'danger' | 'success' | 'warning'
  messages: string[]
}

const TicketAssignedUsersEditModal: React.FC<Props> = ({
  initialAssignedUserInput,
  assignedUsers,
  usernames,
  mediumId,
  ticketId,
  ticketState,
  setAssignedUsers,
  setIsOpenEditModal,
  setSavedAssignedUsers,
  setTicketState,
}) => {
  const [isAddButtonActive, setButttonIsActive] = useState(false)
  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>(null)
  const [assignedUserInput, setAssignedUserInput] =
    useState<{ username: string; role: TicketAssignedUserInput['role'] | '' }>(initialAssignedUserInput)
  const roleOptions = [
    {
      value: 'writer',
      label: 'ライター',
    },
    {
      value: 'director',
      label: 'ディレクター',
    },
  ]

  const changeAssignedUserInput = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.target
    if (name == 'username') {
      const assignedUserIdx = assignedUsers.findIndex((user) => {
        return user.username == value
      })
      setButttonIsActive(assignedUserIdx == -1)
    }
    setAssignedUserInput({ ...assignedUserInput, [name]: value })
  }

  const changeAssignedUser = (index: number, e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.target
    const newAssignedUsers = [...assignedUsers]
    // @ts-ignore
    newAssignedUsers[index][name] = value
    setAssignedUsers(newAssignedUsers)
  }

  const addAssignedUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault()
    const { username, role } = assignedUserInput as TicketAssignedUserInput
    if (!username || !role) {
      alert('担当者と役割を入力してください。')
      return
    }
    const thumbnailUrl = assignedUsers.find((user) => user.username === username)?.thumbnail?.url || ''
    setAssignedUsers([...assignedUsers, { username, role, thumbnail: { url: thumbnailUrl } }])
    setAssignedUserInput(initialAssignedUserInput)
  }

  const deleteAssignedUser = (index: number, e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault()
    const newAssignedUsers = [...assignedUsers]
    newAssignedUsers.splice(index, 1)
    setAssignedUsers(newAssignedUsers)
  }

  const updateAssignedUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault()
    setAlertMessage(null)
    const API_ENDPOINT = '/api/v1/ticket_assigned_users'
    const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''
    axios
      .post(
        API_ENDPOINT,
        {
          medium_id: mediumId,
          ticket_id: ticketId,
          user_names: assignedUsers.map((assign_info) => assign_info.username),
          roles: assignedUsers.map((assign_info) => assign_info.role),
        },
        {
          headers: { 'X-CSRF-TOKEN': csrfToken, 'Content-Type': 'application/json' },
        },
      )
      .then((res) => {
        const assignedUsers = res.data
        setAssignedUserInput(assignedUsers)
        setAssignedUsers(assignedUsers)
        setSavedAssignedUsers(assignedUsers)
        setAlertMessage({ type: 'success', messages: ['更新に成功しました'] })
      })
      .catch((e) => {
        setAlertMessage({ type: 'danger', messages: e.response.data.message })
      })
      .finally(() => {
        fadeModal()
      })
  }

  const handleAssignedUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault()
    const assignedUsernames = assignedUsers.map((user) => user.username)
    const roles = assignedUsers.map((user) => user.role)
    const candidateAssignedUsers = assignedUsers.map((user) => {
      return {
        username: user.username,
        role: user.role,
        thumbnail: {
          url: user.thumbnail.url,
        },
      }
    })
    setTicketState({ ...ticketState, usernames: assignedUsernames, roles: roles })
    setSavedAssignedUsers(candidateAssignedUsers)
    fadeModal()
  }

  const fadeModal = (): void => {
    setIsOpenEditModal(false)
    document.querySelector('body')?.classList.remove('modal-open')
    document.querySelector('.modal-backdrop.fade.show')?.remove()
  }

  const addButton = isAddButtonActive ? (
    <button onClick={addAssignedUser} className="btn btn-primary p-0 add-button">
      追加
    </button>
  ) : (
    <button disabled className="btn btn-primary p-0 add-button">
      追加
    </button>
  )

  const alertMessages = alertMessage?.messages.map((message, idx) => {
    return <Alert key={idx} name={alertMessage.type} message={message} />
  })

  return (
    <div
      className="modal fade bs-example-modal-lg"
      id="ticket_assigned_users_edit_modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="Modal Title"
      aria-hidden="true"
      onClick={(e) => {
        e.preventDefault()

        if (e.target == e.currentTarget) {
          setAlertMessage(null)
          setAssignedUserInput({ username: '', role: '' })
          setAssignedUsers(assignedUsers)
          setAssignedUserInput(initialAssignedUserInput)
          setButttonIsActive(false)
        }
      }}
    >
      <div className="modal-dialog">
        <div className="modal-content p-2">
          <div className="modal-body">
            <h4 className="mt-3 mb-5">担当者を編集</h4>
            {alertMessages && alertMessages}
            <div className="row-wrapper mb-5 mr-4">
              <div className="input-row">
                <input
                  className="form-control input name"
                  list="user-list"
                  type="text"
                  name="username"
                  id="name"
                  value={assignedUserInput.username}
                  onChange={(e) => {
                    changeAssignedUserInput(e)
                  }}
                  placeholder="担当者を入力..."
                />
                <datalist id="user-list">
                  {usernames.map((username, index) => (
                    <option value={username} key={index} />
                  ))}
                </datalist>

                <select
                  className="form-control input role"
                  name="role"
                  id="role"
                  value={assignedUserInput.role}
                  onChange={(e) => {
                    changeAssignedUserInput(e)
                  }}
                >
                  <option value="">役割を選択</option>
                  {roleOptions.map((role) => (
                    <option value={role.value} key={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
              </div>
              {addButton}
            </div>

            {assignedUsers.map((user, index) => (
              <div className="row-wrapper mb-2 mr-4" key={index}>
                <div className="input-row">
                  <input
                    className="form-control input name"
                    list="user-list"
                    type="text"
                    name="name"
                    id="name"
                    value={user.username}
                    onChange={(e) => {
                      changeAssignedUser(index, e)
                    }}
                    placeholder="担当者を入力..."
                  />
                  <datalist id="user-list">
                    {usernames.map((username, index) => (
                      <option value={username} key={index} />
                    ))}
                  </datalist>

                  <select
                    className="form-control input role"
                    name="role"
                    id="role"
                    value={user.role}
                    onChange={(e) => {
                      changeAssignedUser(index, e)
                    }}
                  >
                    <option value="">役割を選択</option>
                    {roleOptions.map((role) => (
                      <option value={role.value} key={role.value}>
                        {role.label}
                      </option>
                    ))}
                  </select>
                </div>
                <button
                  onClick={(e) => {
                    deleteAssignedUser(index, e)
                  }}
                  className="btn btn-danger p-0 delete-button"
                >
                  削除
                </button>
              </div>
            ))}

            <button
              onClick={
                location.pathname.includes(`tickets/${ticketId}`)
                ? updateAssignedUser
                : handleAssignedUser
              }
              className="btn btn-primary mt-5 mb-4 update-button"
            >
              更新する
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TicketAssignedUsersEditModal
