import React, { useState, useEffect, useCallback, useRef, createRef } from 'react'
import { TagSearchResult } from '../types/headerSearch'

type Props = {
  tags: TagSearchResult[]
  searchTarget: string
  handleMouseEnter: (evemt: React.MouseEvent) => void
  handleMouseLeave: (evemt: React.MouseEvent) => void
}

const TagLists: React.FC<Props> = ({ tags, searchTarget, handleMouseEnter, handleMouseLeave }): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>()
  const tagsRef = useRef<React.RefObject<HTMLAnchorElement>[]>([])
  tags.forEach((_, index) => {
    tagsRef.current[index] = createRef<HTMLAnchorElement>()
  })

  const selectIndexAbove = (): void => {
    // 連続キー押下でも確実にsetStateされるように関数使用
    setSelectedIndex((prevIndex) => {
      if (prevIndex === undefined) return undefined
      if (prevIndex === 0) return 0
      // 対象のindexの位置にスクロールされるように
      tagsRef.current[prevIndex - 1].current?.scrollIntoView({ block: 'center' })
      return prevIndex - 1
    })
  }

  const selectIndexBelow = (): void => {
    // 連続キー押下でも確実にsetStateされるように関数使用
    setSelectedIndex((prevIndex) => {
      if (prevIndex === undefined) return 0
      if (prevIndex === tags.length - 1) return tags.length - 1
      // 対象のindexの位置にスクロールされるように
      tagsRef.current[prevIndex + 1].current?.scrollIntoView({ block: 'center' })
      return prevIndex + 1
    })
  }

  const openPageWithEnter = (): void => {
    if (selectedIndex === undefined) return
    const link =
      searchTarget === 'promotionTags'
        ? `/media/${tags[selectedIndex].medium_id}/promotion_tags/${tags[selectedIndex].name}`
        : `/media/${tags[selectedIndex].medium_id}/tags/${tags[selectedIndex].id}`
    window.open(link, '_blank', 'noreferrer')
  }

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          selectIndexAbove()
          break
        case 'ArrowDown':
          selectIndexBelow()
          break
        case 'Enter':
          openPageWithEnter()
          break
        default:
          return
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIndex],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  return (
    <>
      {tags.map((tag, index) => {
        const tagUrl =
          searchTarget === 'promotionTags' ? `/media/${tag.medium_id}/promotion_tags/${tag.name}` : `/media/${tag.medium_id}/tags/${tag.id}`
        const isSelected = index === selectedIndex
        return (
          <a
            href={tagUrl}
            target="_blank"
            className="p-3"
            style={{
              color: 'black',
              width: '100%',
              fontSize: '12px',
              borderBottom: '1px solid darkgray',
              fontWeight: 'bold',
              backgroundColor: `${isSelected ? '#e9ecef' : '#fff'}`,
            }}
            key={tag.id}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            rel="noreferrer"
            ref={tagsRef.current[index]}
          >
            {tag.name}
          </a>
        )
      })}
    </>
  )
}

export default TagLists
