import React from 'react'
import { Article } from '../types/articles'

type Props = {
  checked: Article[]
  mediaId: number
}

const Header: React.FC<Props> = ({ checked, mediaId }) => {
  const tagAlert = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (checked.length == 0) {
      alert('記事が選択されていません')
      event.stopPropagation()
    }
  }

  return (
    <>
      <button className="btn btn-success float-right" onClick={(event) => tagAlert(event)} data-target="#my-modal" data-toggle="modal">
        タグを一括追加
      </button>

      <a className="btn btn-dark mb-5 float-right mr-2" rel="nofollow" data-method="post" href={`/media/${mediaId}/articles/export`}>
        CSVエクスポート
      </a>
    </>
  )
}

export default Header
