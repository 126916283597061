import React, { useState } from 'react'
import TicketAssignedUsersEditModal from './TicketAssignedUsersEditModal'
import { TicketAssignedUser, TicketAssignedUserInput, TicketNewState } from './types/ticket'

type Props = {
  mediumId: number
  ticketId?: number
  usernames: string[]
  initialAssignedUsers: TicketAssignedUser[]
  ticketState: TicketNewState
  setTicketState: React.Dispatch<React.SetStateAction<TicketNewState>>
  // FIXME
  // canEdit: boolean
}

const TicketAssignedUsers: React.FC<Props> = ({
  usernames,
  mediumId,
  ticketId,
  initialAssignedUsers,
  ticketState,
  setTicketState /*, canEdit*/,
}) => {
  const initialAssignedUserInput: { username: string; role: TicketAssignedUserInput['role'] | '' } = { username: '', role: '' }
  const [assignedUsers, setAssignedUsers] = useState<TicketAssignedUser[]>(initialAssignedUsers)
  const [savedAssignedUsers, setSavedAssignedUsers] = useState<TicketAssignedUser[]>(initialAssignedUsers)
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false)

  // FIXME: 仮データ。編集できる立場とできない立場がありそうだったので。
  const canEdit = true

  const getRoleName = (role: TicketAssignedUser['role']): string => {
    switch (role) {
      case 'writer':
        return 'ライター'
      case 'director':
        return 'ディレクター'
      default:
        return ''
    }
  }

  return (
    <>
      <div className="form-group" id="ticket_assigned_users">
        <div className="label-wrapper mb-2">
          <label htmlFor="ticket_担当者" className="m-0">
            担当者
          </label>
          {canEdit && (
            <button
              onClick={(e) => {
                e.preventDefault()
                setIsOpenEditModal(true)
              }}
              data-toggle="modal"
              data-target="#ticket_assigned_users_edit_modal"
              className="btn btn-primary p-0 edit-button"
            >
              編集する
            </button>
          )}
        </div>

        <div>
          {!!savedAssignedUsers.length ? (
            savedAssignedUsers.map((user) => (
              <div className="user-row" key={user.username}>
                <img src={user.thumbnail.url} alt={user.username} className="user-icon" />
                <p className="m-0">{user.username}</p>
                <p className="role text-primary m-0">{getRoleName(user.role)}</p>
              </div>
            ))
          ) : (
            <p className="pl-3">担当者はいません</p>
          )}
        </div>
      </div>

      {isOpenEditModal && (
        <TicketAssignedUsersEditModal
          initialAssignedUserInput={initialAssignedUserInput}
          assignedUsers={assignedUsers}
          usernames={usernames}
          mediumId={mediumId}
          ticketId={ticketId}
          ticketState={ticketState}
          setAssignedUsers={setAssignedUsers}
          setIsOpenEditModal={setIsOpenEditModal}
          setSavedAssignedUsers={setSavedAssignedUsers}
          setTicketState={setTicketState}
        />
      )}
    </>
  )
}
export default TicketAssignedUsers
