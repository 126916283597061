import React, { useState, useEffect, useCallback, useRef, createRef } from 'react'
import moment from 'moment'
import { TicketSearchResult } from '../types/headerSearch'

type Props = {
  tickets: TicketSearchResult[]
  handleMouseEnter: (evemt: React.MouseEvent) => void
  handleMouseLeave: (evemt: React.MouseEvent) => void
}

const TicketLists: React.FC<Props> = ({ tickets, handleMouseEnter, handleMouseLeave }): JSX.Element => {
  const genres = { 種まき: 'success', 水やり: 'primary', 収穫: 'danger' }
  const statuses = { 未着手: 'secondary', 進行中: 'primary', 検証中: 'primary', 成功: 'success', 失敗: 'danger' }

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>()
  const ticketsRef = useRef<React.RefObject<HTMLAnchorElement>[]>([])
  tickets.forEach((_, index) => {
    ticketsRef.current[index] = createRef<HTMLAnchorElement>()
  })

  const selectIndexAbove = (): void => {
    // 連続キー押下でも確実にsetStateされるように関数使用
    setSelectedIndex((prevIndex) => {
      if (prevIndex === undefined) return undefined
      if (prevIndex === 0) return 0
      // 対象のindexの位置にスクロールされるように
      ticketsRef.current[prevIndex - 1].current?.scrollIntoView({ block: 'center' })
      return prevIndex - 1
    })
  }

  const selectIndexBelow = (): void => {
    // 連続キー押下でも確実にsetStateされるように関数使用
    setSelectedIndex((prevIndex) => {
      if (prevIndex === undefined) return 0
      if (prevIndex === tickets.length - 1) return tickets.length - 1
      // 対象のindexの位置にスクロールされるように
      ticketsRef.current[prevIndex + 1].current?.scrollIntoView({ block: 'center' })
      return prevIndex + 1
    })
  }

  const openPageWithEnter = (): void => {
    if (selectedIndex === undefined) return
    const link = `/media/${tickets[selectedIndex].medium_id}/tickets/${tickets[selectedIndex].id}`
    window.open(link, '_blank', 'noreferrer')
  }

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          selectIndexAbove()
          break
        case 'ArrowDown':
          selectIndexBelow()
          break
        case 'Enter':
          openPageWithEnter()
          break
        default:
          return
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIndex],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  return (
    <>
      {tickets.map((ticket, index) => {
        const isSelected = index === selectedIndex
        return (
          <a
            href={`/media/${ticket.medium_id}/tickets/${ticket.id}`}
            target="_blank"
            className="p-3"
            style={{
              color: 'black',
              width: '100%',
              fontSize: '12px',
              borderBottom: '1px solid darkgray',
              fontWeight: 'bold',
              backgroundColor: `${isSelected ? '#e9ecef' : '#fff'}`,
            }}
            key={ticket.id}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            rel="noreferrer"
            ref={ticketsRef.current[index]}
          >
            <span className={`badge badge-${genres[ticket.genre]}`}>{ticket.genre}</span>
            {ticket.ticket_assigned_users.length === 0 ? (
              <></>
            ) : (
              ticket.ticket_assigned_users.map((assignedUser) => (
                <span className="user-row" key={assignedUser.user.username} style={{ margin: '0 5px' }}>
                  <img
                    src={assignedUser.user.thumbnail.url}
                    alt={assignedUser.user.username}
                    height="30"
                    width="30"
                    className="user-icon rounded-circle"
                  />
                </span>
              ))
            )}
            <h6>
              {ticket.title}
              <span className={`badge badge-${statuses[ticket.status]} float-right`}>{ticket.status}</span>
            </h6>
            {moment(ticket.validate_start_at).format('YYYY-MM-DD')} - {moment(ticket.validate_end_at).format('YYYY-MM-DD')}
          </a>
        )
      })}
    </>
  )
}

export default TicketLists
