import React, { useState, useRef } from 'react'
import Editor from './Editor'
import axios from 'axios'

type Props = {
  currentMedium: {
    id: string
  }
  currentUserId: {
    id: BigInteger
  }
}

const TicketTemplatesNew: React.FC<Props> = ({ currentMedium, currentUserId }) => {
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState('')
  const IMAGE_SIZE_LIMIT = 10

  const submitButton = useRef(null)

  const submitTemplate = (): void => {
    if (!title || !description) {
      alert('タイトルと概要を入力してください')
      return
    }
    const url = `/api/v1/media/${currentMedium.id}/ticket_templates/`
    const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''
    const params = {
      ticket_template: {
        title: title,
        description: description,
        user_id: currentUserId,
      },
    }

    axios
      .post(url, params, {
        headers: { 'X-CSRF-TOKEN': csrfToken, 'Content-Type': 'application/json' },
      })
      .then(() => {
        alert('施策テンプレートを追加しました')
        window.location.href = `/media/${currentMedium.id}/ticket_templates`
      })
      .catch(() => {
        alert('施策を追加できませんでした')
      })
  }

  const uploadImageToS3 = async (file: File): Promise<void> => {
    if (file.size > IMAGE_SIZE_LIMIT * 1024 * 1024) {
      setErrorMessage(`ファイルの上限サイズ${IMAGE_SIZE_LIMIT}MBを超えています`)
      return
    }
    try {
      const API_ENDPOINT = `/api/v1/ticket_comments/upload_image`
      const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''

      const formData = new FormData()
      formData.append('file', file)
      const imageUrl = await axios.post(API_ENDPOINT, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRF-TOKEN': csrfToken,
        },
      })
      return imageUrl.data.uploaded_file_full_path
    } catch (e) {
      alert('画像のアップロードに失敗しました')
    }
  }

  const clickSubmitButton = (): void => {
    //@ts-ignore
    submitButton.current?.click()
  }

  return (
    <div>
      <h3 className="mb-5">施策テンプレート作成</h3>
      <div className="mb-5">
        <h5 className="mb-2">タイトル</h5>
        <input
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
          placeholder="施策テンプレート名を入力してください"
          className="col-md-12"
        />
      </div>
      <div className="mb-5">
        <h5 className="mb-2">概要</h5>
        <Editor
          value={description}
          onChange={(data) => {
            setDescription(data.text)
          }}
          onImageUpload={uploadImageToS3}
          submit={clickSubmitButton}
        />
        {!!errorMessage && <p className="text-danger">{errorMessage}</p>}
      </div>
      <button className="btn btn-primary col-md-12" onClick={submitTemplate} ref={submitButton}>
        作成
      </button>
    </div>
  )
}
export default TicketTemplatesNew
