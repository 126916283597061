import axios from 'axios'
import React, { useState, useRef, useEffect } from 'react'
import 'easymde/dist/easymde.min.css'
import Editor from './Editor'
import TicketTemplatesUseModal from './TicketTemplatesUseModal'
import { Ticket, User, Template } from '../types/types'

type Props = {
  ticket: Ticket
  currentUser: User
  ticketTemplates: Template[]
  toggleShouldShowMessageBeforeUnload: (isEditing: boolean) => void
}

const TicketNewComment: React.FC<Props> = ({ ticket, currentUser, ticketTemplates, toggleShouldShowMessageBeforeUnload }) => {
  const { id: ticketId } = ticket
  const { id: currentUserId, thumbnail: currentUserThumbnail, username: currentUsername } = currentUser
  const [input, setInput] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isTemplatesUseModalOpen, setIsTemplatesUseModalOpen] = useState<boolean>(false)
  const IMAGE_SIZE_LIMIT = 10

  const submitButton = useRef(null)

  const handleSubmit = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    e.preventDefault()
    if (!input) {
      alert('コメントを入力してください')
      return
    }
    setLoading(true)
    toggleShouldShowMessageBeforeUnload(false)
    const API_ENDPOINT = `/api/v1/ticket_comments`
    const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''
    axios
      .post(
        API_ENDPOINT,
        {
          ticket_id: ticketId,
          description: input,
          user_id: currentUserId,
        },
        {
          headers: { 'X-CSRF-TOKEN': csrfToken, 'Content-Type': 'application/json' },
        },
      )
      .then(() => {
        setInput('')
        alert('コメントを追加しました')
        location.reload()
      })
  }

  const uploadImageToS3 = async (file: File): Promise<void> => {
    if (file.size > IMAGE_SIZE_LIMIT * 1024 * 1024) {
      setErrorMessage(`ファイルの上限サイズ${IMAGE_SIZE_LIMIT}MBを超えています`)
      return
    }
    try {
      const API_ENDPOINT = `/api/v1/ticket_comments/upload_image`
      const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''
      const formData = new FormData()
      formData.append('file', file)
      const imageUrl = await axios.post(API_ENDPOINT, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRF-TOKEN': csrfToken,
        },
      })
      return imageUrl.data.uploaded_file_full_path
    } catch (e) {
      alert('画像のアップロードに失敗しました')
    }
  }

  const setTemplate = (templateDescription: Template['description']): void => {
    setInput(input + '  \n' + templateDescription)
  }

  const clickSubmitButton = (): void => {
    //@ts-ignore
    submitButton.current?.click()
  }

  useEffect(() => {
    if (!input) return
    toggleShouldShowMessageBeforeUnload(true)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [input])

  return (
    <>
      <div className="row mb-4">
        <div className="col-md-1">
          <img src={currentUserThumbnail.url} alt="icon" className="rounded-circle mb-2" width="50" height="50" />
          <div className="small">{currentUsername}</div>
        </div>
        <div className="col-md-11 mb-4">
          <div className="text-end mb-2">
            <div
              className="btn btn-outline-primary"
              onClick={() => {
                setIsTemplatesUseModalOpen(true)
              }}
            >
              <i className="fa fa-plus mr-2" />
              <span data-toggle="modal" data-target="#ticket_templates_use_modal">
                テンプレートを利用する
              </span>
            </div>
          </div>
          <Editor
            value={input}
            onChange={(data) => {
              setInput(data.text)
            }}
            onImageUpload={uploadImageToS3}
            submit={clickSubmitButton}
          />
          {!!errorMessage && <p className="text-danger">{errorMessage}</p>}
          {loading ? (
            <div className="btn btn-primary float-right" onClick={handleSubmit}>
              <span className="spinner-border mr-2" role="status"></span>
              投稿中...
            </div>
          ) : (
            <span className="btn btn-primary float-right" onClick={handleSubmit} ref={submitButton}>
              <i className="fas fa-plus mr-2" />
              コメントする
            </span>
          )}
        </div>
      </div>
      {isTemplatesUseModalOpen && <TicketTemplatesUseModal defaultTicketTemplates={ticketTemplates} setTemplate={setTemplate} />}
    </>
  )
}
export default TicketNewComment
