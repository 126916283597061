import React, { useEffect, useState } from 'react'
import TicketTemplateList from './TicketTemplateList'
import TicketTemplatesDeleteModal from './TicketTemplatesDeleteModal'
import TicketTemplatesSearchInput from './TicketTemplatesSearchInput'
import axios from 'axios'
import { Template } from '../types/types'

type Props = {
  currentMedium: {
    id: string
  }
}

const TicketTemplates: React.FC<Props> = ({ currentMedium }) => {
  const [templateForDelete, setTemplateForDelete] = useState<Template>()
  const [defaultTicketTemplates, setDefaultTicketTemplates] = useState<Template[]>([])
  const [ticketTemplates, setTicketTemplates] = useState<Template[]>([])

  useEffect(() => {
    const fetchTicketTemplates = async (): Promise<void> => {
      const url = `/api/v1/media/${currentMedium.id}/ticket_templates/`
      await axios.get(url).then((response) => {
        setTicketTemplates(response.data.ticket_templates)
        setDefaultTicketTemplates(response.data.ticket_templates)
      })
    }
    fetchTicketTemplates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterTemplates = (searchInput: string): void => {
    if (!searchInput) {
      setTicketTemplates(defaultTicketTemplates)
      return
    }
    const results = defaultTicketTemplates.filter((template) => template.title.includes(searchInput))
    setTicketTemplates(results)
  }

  return (
    <>
      <div>
        <h3 className="mb-4">施策テンプレート一覧</h3>
        <div className="text-right mb-4">
          <a href={`/media/${currentMedium.id}/ticket_templates/new`} className="btn btn-primary">
            新規作成
          </a>
        </div>
        <TicketTemplatesSearchInput filterTemplates={filterTemplates} />
        <TicketTemplateList
          ticketTemplates={ticketTemplates}
          currentMedium={currentMedium}
          openDeleteModal={(template) => {
            setTemplateForDelete(template)
          }}
        />
      </div>
      {templateForDelete && <TicketTemplatesDeleteModal template={templateForDelete} />}
    </>
  )
}
export default TicketTemplates
