import React, { useState } from 'react'
import { WithContext as ReactTags } from 'react-tag-input'

type Tag = {
  id: number
  name: string
}

type ReactTag = {
  id: string
  name: string
}

type Props = {
  titles: string[]
  articleIds?: string[]
  isForAddingMultiTags?: boolean
  tagNames: Tag[]
  selectedTags?: Tag[] | []
  mediumId: string
}

const KEYCODES = {
  enter: 13,
}

const TITLE_NUMS_DISPLAYED = 5

const TagsModal: React.FC<Props> = ({ titles, articleIds, selectedTags = [], tagNames, isForAddingMultiTags = false, mediumId }) => {
  const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''

  const changeTagPropertyNameToText = (tags: Tag[]): ReactTag[] => {
    return tags.map((tag) => ({ id: `${tag.id}`, name: tag.name }))
  }
  const changeTagPropertyNameToTag = (tags: ReactTag[]): Tag[] => {
    return tags.map((tag) => ({ id: Number(tag.id), name: tag.name }))
  }

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tags, setTags] = useState<ReactTag[]>(isForAddingMultiTags ? [] : changeTagPropertyNameToText(selectedTags))

  const handleDelete = (i: number): void => {
    setTags(tags.filter((_, index) => index !== i))
  }

  const handleAddition = (tag: ReactTag): void => {
    setTags([...tags, tag])
  }

  const addTag = (): void => {
    const tagsForVariables = changeTagPropertyNameToTag(tags)
    if (articleIds?.length == 0) {
      alert('記事が選択されていません。')
      return
    }

    if (tagsForVariables.length == 0) {
      alert('タグが入力されていません。')
      return
    }
    const tagNames = tagsForVariables.map((tag) => tag.name)
    setIsLoading(true)

    const url = `/api/v1/media/${mediumId}/articles/update_tags`
    const params = {
      article_ids: articleIds,
      tag_names: tagNames,
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken,
      },
      body: JSON.stringify(params),
    }).then((response) => {
      if (response.ok) {
        alert('タグを追加しました')
        location.reload()
      } else {
        alert('タグの追加に失敗しました')
        setIsLoading(false)
      }
    })
  }

  //  @ts-ignore
  const RemoveComponent = ({ onRemove }): JSX.Element => {
    return (
      <div onClick={onRemove} className="delete-btn">
        x
      </div>
    )
  }

  return (
    <div
      className="modal fade bs-example-modal-lg"
      id="my-modal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="Modal Title"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md tag-form p-4">
        <div className="modal-content p-4">
          <div className="modal-header" style={{ display: 'block' }}>
            {titles.slice(0, TITLE_NUMS_DISPLAYED).map((title, index) => (
              <h4 className="modal-title" key={index}>
                {title}
              </h4>
            ))}
            <p className="text-end">
              {titles.length > 5 ? '...' : ''}（全{titles.length}件）
            </p>
          </div>
          <div className="modal-body mt-4">
            <div className="tag-form-content">
              <div className="mr-4">タグ名</div>
              <div className="input-box">
                {/*  @ts-ignore */}
                <ReactTags
                  tags={tags}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  inputFieldPosition="top"
                  autocomplete={false}
                  placeholder="記事に関連するタグを入力しENTERで追加"
                  delimiters={[KEYCODES.enter]}
                  removeComponent={RemoveComponent}
                  suggestions={changeTagPropertyNameToText(tagNames)}
                  labelField={'name'}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {isLoading ? (
              <div>
                <span className="spinner-border mr-2" role="status"></span>
                登録中...
              </div>
            ) : (
              <button className="btn btn-primary add" onClick={addTag}>
                タグを追加する
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default TagsModal
