import React from 'react'

const Loading: React.FC = () => {
  const style = {
    top: '50%',
    left: '50%',
    width: '32px',
    height: '32px',
    border: '0.25em solid currentColor',
    borderRadius: '50%',
    borderRightColor: 'transparent',
    animation: 'r1 1s linear infinite',
  }

  return (
    <>
      <style>
        {`
          @keyframes r1 {
            0%   { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          .loading {

          }
        `}
      </style>
      <div className="position-absolute" {...{ style }} />
    </>
  )
}

export default Loading
