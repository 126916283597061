import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from 'chart.js'
import Search from './search'
import { MonthlyGraphMonthlyData, GraphOption, MonthlyGraphDatasets, ChartData } from '../types/chart'
import Loading from '../common/Loading'

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler)
type Props = {
  monthlyGraph: MonthlyGraphMonthlyData
  paramsData: { [key: string]: string }
  location: string
  mediumId: number
  defaultFromDate?: string
  defaultToDate?: string
}

const Monthly: React.FC<Props> = ({ paramsData, location, mediumId, defaultFromDate, defaultToDate }) => {
  const [chartData, setChartData] = useState<{
    monthlyGraph: MonthlyGraphMonthlyData
  }>({ monthlyGraph: { keys: {}, labels: [], datasets: [], options: {} } })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true)
  const [keys, setKeys] = useState<string[]>([])
  const [fromDate, setFromDate] = useState<string>(
    defaultFromDate ? moment(defaultFromDate).format('YYYY-MM-DD') : moment().subtract(1, 'year').format('YYYY-MM-DD'),
  )
  const [toDate, setToDate] = useState<string>(defaultToDate ? moment(defaultToDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'))

  useEffect(() => {
    getChartData()
    // eslint-disable-next-line
  }, [])

  const getChartData = async (): Promise<void> => {
    const params = {
      promotion_tag_name: paramsData.id,
      from_date: fromDate,
      to_date: toDate,
    }
    const url =
      location === 'promotion_tags'
        ? `/api/v1/media/${mediumId}/promotion_tags/monthly_charts`
        : `/api/v1/media/${mediumId}/tags/${paramsData.id}/monthly_charts`

    await axios({ method: 'get', url: url, params })
      .then((results) => {
        const monthlyGraphData = results.data.monthly_graph
        setChartData({ monthlyGraph: monthlyGraphData })
        setKeys(Object.entries(monthlyGraphData.keys).map((key) => key[0]))
      })
      .finally(() => {
        setIsFirstLoading(false)
      })
  }

  const graphOptions = (val: string): GraphOption => {
    return {
      scales: {
        yAxes: {
          title: {
            display: true,
            fontSize: 14,
            fontStyle: 'normal',
            padding: 20,
            text: chartData.monthlyGraph.options[val],
          },
          ticks: {
            // @ts-ignore
            callback: (value: number) => {
              return value.toLocaleString()
            },
          },
        },
      },
    }
  }

  const Graphcolor = (): string => {
    const color = [Math.floor(Math.random() * 55 + 200), Math.floor(Math.random() * 55 + 200), Math.floor(Math.random() * 55 + 200)]

    return `rgb(${color[0]},${color[1]},${color[2]}`
  }

  const getGraphData = (val: string): ChartData[] => {
    return chartData.monthlyGraph.datasets.map((graph: MonthlyGraphDatasets) => {
      const keyword = Object.keys(graph).map((key) => {
        return key
      })[0]
      const data = graph[keyword].map((data: { [key: string]: string }) => data[val])

      return {
        // @ts-ignore
        label: keyword,
        // @ts-ignore
        data: data,
        borderColor: Graphcolor(),
      }
    })
  }

  return (
    <>
      <Search
        setChartDataForPromotionTagGraph={setChartData}
        paramsData={paramsData}
        location={location}
        setIsLoading={setIsLoading}
        setFromDate={setFromDate}
        setToDate={setToDate}
        fromDate={fromDate}
        toDate={toDate}
        mediumId={mediumId}
      />

      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {keys.map((key, index) => {
          return (
            <li key={key} className="nav-item">
              <a
                className={`${key} nav-link ${index == 0 ? 'active' : ''}`}
                aria-selected={index == 0 ? true : false}
                data-toggle="tab"
                href={`#${key}`}
                role="tabpanel"
              >
                {chartData.monthlyGraph.keys[key]}
              </a>
            </li>
          )
        })}
      </ul>

      <div id="promotionTabContent" className="tab-content mb-4">
        {isFirstLoading && (
          <div className="col-md-12">
            <Loading />
          </div>
        )}

        {keys.map((key, index) => {
          return (
            <div
              key={key}
              id={key}
              className={`tab-pane ${index == 0 ? 'active' : ''} fade show false position-relative`}
              aria-labelledby={`${key}-tab`}
              role="tabpanel"
            >
              {isLoading && <Loading />}
              <Line
                data={{
                  labels: chartData.monthlyGraph.labels,
                  datasets: getGraphData(key),
                }}
                // @ts-ignore
                options={graphOptions(key)}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Monthly
