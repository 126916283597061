import React from 'react'
import { CurrentMedium } from '../../types/common'
import { Promotion } from '../../types/promotions'
import Loading from '../../common/Loading'

type Props = {
  promotions: Promotion[]
  checkedPromotions: Promotion[]
  currentMedium: CurrentMedium
  isCheckedAll: boolean
  isLoading: boolean
  toggleCheckAll: (e: React.ChangeEvent<HTMLInputElement>) => void
  toggleCheckPromotion: (targetPromotion: Promotion) => void
  setDataForEditOne: (promotion: Promotion) => void
}


const PromotionsIndexPromotionList: React.FC<Props> = ({
  promotions,
  checkedPromotions,
  currentMedium,
  isCheckedAll,
  isLoading,
  toggleCheckAll,
  toggleCheckPromotion,
  setDataForEditOne,
}) => {
  
  /**
   * 数値を日本の通貨フォーマット（小数点区切り）に変換します。
   *
   * @param {number} price - フォーマットする数値。
   * @returns {string} フォーマットされた数値を表す文字列。
   */
  const priceFormat = (price: number): string => {
    return '¥' + new Intl.NumberFormat('ja-JP', { style: 'decimal' }).format(price);
  }

  return (
    <>
      {isLoading && <Loading />}
      <table className="table">
        <thead>
          <tr>
            <th className="col-md-1">
              <input id="check_all" onChange={(e) => toggleCheckAll(e)} type="checkbox" checked={isCheckedAll} />
            </th>
            <th className="col-md-7">プロモーション名</th>
            <th className="col-md-2">ASP</th>
            <th className="col-md-2">デフォルト金額</th>
            <th className="col-md-2">コンバージョンの自動取得</th>
            <th className="col-md-2" />
          </tr>
        </thead>
        <tbody>
          {promotions.map((promotion, idx) => (
            <tr key={idx}>
              <td>
                <input
                  type="checkbox"
                  id={`${promotion.id}`}
                  onChange={() => toggleCheckPromotion(promotion)}
                  checked={checkedPromotions.map((promotion) => promotion.id).includes(promotion.id)}
                />
              </td>
              <td>
                <p>{promotion.name}</p>
                {promotion.tags.map((tag) => (
                  <a className="badge badge-success mr-2 mb-2" href={`/media/${currentMedium.id}/promotion_tags/${tag.id}`} key={tag.id}>
                    {tag.name}
                  </a>
                ))}
              </td>
              <td>{promotion.asp}</td>
              <td>{promotion.default_price == null ? '未設定' : priceFormat(promotion.default_price)}</td>
              <td>{promotion.allow_auto_cv_import ? '有効' : '無効'}</td>
              <td>
                <button
                  className="btn btn-success"
                  data-target="#promotions_index_tags_modal"
                  data-toggle="modal"
                  onClick={() => {
                    setDataForEditOne(promotion)
                  }}
                >
                  タグを編集
                </button>
                <a
                  className="btn btn-primary mt-2"
                  href={`/media/${currentMedium.id}/promotions/${promotion.id}/edit`}
                >
                  プロモーションを編集
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default PromotionsIndexPromotionList
