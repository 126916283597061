import React, { useState, useEffect, useCallback, useRef, createRef } from 'react'
import { ArticleSearchResult } from '../types/headerSearch'

type Props = {
  articles: ArticleSearchResult[]
  handleMouseEnter: (evemt: React.MouseEvent) => void
  handleMouseLeave: (evemt: React.MouseEvent) => void
}

const ArticleLists: React.FC<Props> = ({ articles, handleMouseEnter, handleMouseLeave }): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>()
  const articlesRef = useRef<React.RefObject<HTMLAnchorElement>[]>([])
  articles.forEach((_, index) => {
    articlesRef.current[index] = createRef<HTMLAnchorElement>()
  })

  const selectIndexAbove = (): void => {
    // 連続キー押下でも確実にsetStateされるように関数使用
    setSelectedIndex((prevIndex) => {
      if (prevIndex === undefined) return undefined
      if (prevIndex === 0) return 0
      // 対象のindexの位置にスクロールされるように
      articlesRef.current[prevIndex - 1].current?.scrollIntoView({ block: 'center' })
      return prevIndex - 1
    })
  }

  const selectIndexBelow = (): void => {
    // 連続キー押下でも確実にsetStateされるように関数使用
    setSelectedIndex((prevIndex) => {
      if (prevIndex === undefined) return 0
      if (prevIndex === articles.length - 1) return articles.length - 1
      // 対象のindexの位置にスクロールされるように
      articlesRef.current[prevIndex + 1].current?.scrollIntoView({ block: 'center' })
      return prevIndex + 1
    })
  }

  const openPageWithEnter = (): void => {
    if (selectedIndex === undefined) return
    const link = `/media/${articles[selectedIndex].medium_id}/articles/${articles[selectedIndex].id}`
    window.open(link, '_blank', 'noreferrer')
  }

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          selectIndexAbove()
          break
        case 'ArrowDown':
          selectIndexBelow()
          break
        case 'Enter':
          openPageWithEnter()
          break
        default:
          return
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIndex],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  return (
    <>
      {articles.map((article, index) => {
        const isSelected = index === selectedIndex
        return (
          <a
            href={`/media/${article.medium_id}/articles/${article.id}`}
            target="_blank"
            className="p-3"
            style={{
              color: 'black',
              width: '100%',
              fontSize: '12px',
              borderBottom: '1px solid darkgray',
              fontWeight: 'bold',
              backgroundColor: `${isSelected ? '#e9ecef' : '#fff'}`,
            }}
            key={article.id}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            rel="noreferrer"
            ref={articlesRef.current[index]}
          >
            {article.title}
          </a>
        )
      })}
    </>
  )
}

export default ArticleLists
