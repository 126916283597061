import React from 'react'
import { Article } from '../types/articles'

type Props = {
  articles: Article[]
  setChecked: React.Dispatch<React.SetStateAction<Article[]>>
}

const Columns: React.FC<Props> = ({ articles, setChecked }) => {
  const bulkCheck = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      allCheck()
    } else {
      clearCheck()
    }
  }

  const allCheck = (): void => setChecked(articles.map((article: Article) => article))

  const clearCheck = (): void => setChecked([])

  return (
    <thead>
      <tr>
        <th>
          <input id="check-all-articles" onChange={(e) => bulkCheck(e)} type="checkbox" />
        </th>
        <th>タイトル</th>
        <th>
          <a className="sort_link " href={`${window.location.pathname}?q%5Bs%5D=keyword+asc`}>
            KW
          </a>
        </th>
        <th>現状検索順位</th>
        <th>目標検索順位</th>
        <th>順位改善幅</th>
        <th>現状PV</th>
        <th>目標PV</th>
        <th>現状CVR</th>
        <th>目標CVR</th>
        <th>CVR改善幅</th>
        <th>現状単価</th>
        <th className="col-2">目標単価</th>
        <th>現状売上</th>
        <th>目標売上</th>
        <th>売上改善幅</th>
        <th>
          <a className="sort_link " href={`${window.location.pathname}?q%5Bs%5D=published_at+asc`}>
            作成日
          </a>
        </th>
        <th>
          <a className="sort_link " href={`${window.location.pathname}?q%5Bs%5D=modified_at+as`}>
            更新日
          </a>
        </th>
        <th>アクション</th>
      </tr>
      <tr></tr>
    </thead>
  )
}

export default Columns
