import React, { useState } from 'react'
import moment from 'moment'
import axios from 'axios'
import { Article } from '../types/articles'

type Props = {
  dateFields: React.RefObject<HTMLInputElement>
  articles: Article[]
  checked: Article[]
  setChecked: React.Dispatch<React.SetStateAction<Article[]>>
  mediaId: number
}

type ArticleState = {
  title: string
  keyword: string
  goal_rank: string
  goal_pv: string
  goal_cvr: string
  goal_price: string
}

const Record: React.FC<Props> = ({ articles, checked, dateFields, setChecked, mediaId }) => {
  const initialArticleState = {
    title: '',
    keyword: '',
    goal_rank: '',
    goal_pv: '',
    goal_cvr: '',
    goal_price: '',
  }
  const [articleState, setArticleState] = useState<ArticleState>(initialArticleState)
  const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''

  const changeArticleState = (key: string, value: string): void => {
    setArticleState({ ...articleState, [key]: value })
  }

  const toggleChecked = (tgt: Article): void => {
    if (checked.includes(tgt)) {
      setChecked([...checked.filter((item: Article) => item !== tgt)])
    } else {
      setChecked([...checked.concat([tgt])])
    }
  }

  const editArticle = (form: HTMLButtonElement, article: Article): void => {
    const id = form.dataset.id
    const table = dateFields.current
    if (!table) return
    const tr = table.querySelector(`tr[data-id='${id}']`) as HTMLElement
    const hiddenTags = tr.querySelectorAll('.d-none')
    const displayedTags = tr.querySelectorAll('.d-block')
    toggleDisplay(hiddenTags)
    toggleDisplay(displayedTags)

    if (form.innerHTML == '編集') {
      setArticleState({
        title: article.title,
        keyword: article.keyword,
        goal_rank: article.goal_rank,
        goal_pv: article.goal_pv,
        goal_cvr: article.goal_cvr,
        goal_price: article.goal_price,
      })
      configureTitleHeight()
      form.innerHTML = '更新'
    } else {
      const preArticleState = {
        title: article.title,
        keyword: article.keyword,
        goal_rank: article.goal_rank,
        goal_pv: article.goal_pv,
        goal_cvr: article.goal_cvr,
        goal_price: article.goal_price,
      }
      submitForm(preArticleState, tr)
      form.innerHTML = '編集'
    }
  }

  const submitForm = async (preArticleState: ArticleState, tr: HTMLElement): Promise<void> => {
    // ここ
    const id = tr.dataset.id
    const url = `/api/v1/media/${mediaId}/articles/${id}`
    const params = {
      title: articleState.title,
      keyword: articleState.keyword,
      goal_rank: articleState.goal_rank,
      goal_pv: articleState.goal_pv,
      goal_cvr: articleState.goal_cvr,
      goal_price: articleState.goal_price,
    }
    if (JSON.stringify(params) !== JSON.stringify(preArticleState)) {
      await axios
        .patch(
          url,
          {
            article: params,
          },
          { headers: { 'X-CSRF-TOKEN': csrfToken, 'Content-Type': 'application/json' } },
        )
        .then((response) => {
          alert('記事を更新しました')
          configureKeyword(response.data.keyword, tr)
          const title = tr.querySelector('.title') as HTMLElement
          const goalRank = tr.querySelector('.goal_rank') as HTMLElement
          const goalPv = tr.querySelector('.goal_pv') as HTMLElement
          const goalCvr = tr.querySelector('.goal_cvr') as HTMLElement
          const goalPrice = tr.querySelector('.goal_price') as HTMLElement

          title.innerText = response.data.title
          goalRank.innerText = response.data.goal_rank
          goalPv.innerText = response.data.goal_pv
          goalCvr.innerText = (parseFloat(response.data.goal_cvr) * 100).toFixed(2) + '%'
          goalPrice.innerText = response.data.goal_price
        })
        .catch((err) => {
          alert(`エラーで失敗しました\n${err.message}`)
        })
    }
  }

  const toggleDisplay = (tags: NodeListOf<Element>): void => {
    Array.from(tags).map((tag: Element) => {
      tag.classList.toggle('d-none')
      tag.classList.toggle('d-block')
    })
  }

  const configureTitleHeight = (): void => {
    const flexTextarea = (el: Element): void => {
      const dummy = el.querySelector('.flex-textarea-dummy') as Element
      el.querySelector('.flex-textarea-textarea')?.addEventListener('input', (e) => {
        const { value } = e.target as HTMLInputElement
        dummy.textContent = value + '\u200b'
      })
    }

    if (dateFields.current) dateFields.current.querySelectorAll('.flex-textarea').forEach(flexTextarea)
  }

  const deleteArticle = (target: HTMLButtonElement): void => {
    if (!dateFields.current) return

    const id = target.dataset.id
    const tr = dateFields.current.querySelector(`tr[data-id='${id}']`)

    const checked = confirm('この記事を削除しますか？')
    if (checked) {
      // @ts-ignore
      deleteAction(tr)
    } else {
      return
    }
  }

  const deleteAction = async (tr: HTMLFormElement): Promise<void> => {
    // ここ
    const id = tr.dataset.id
    const url = `/api/v1/media/${mediaId}/articles/${id}`
    await axios
      .delete(url, {
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'Content-Type': 'application/json',
        },
        data: {
          id: id,
        },
      })
      .then((res) => {
        tr.remove()
        alert(res.data.message)
      })
      .catch((err) => {
        alert(`エラーで失敗しました\n${err.message}`)
      })
  }

  const configureKeyword = (keyword: string, tr: HTMLElement): void => {
    const keywordBlock = tr.querySelectorAll('.d-block')[1]
    const keywordForm = tr.querySelector('.keyword') as HTMLElement
    if (!keywordForm) return

    if (!keyword) {
      keywordBlock.classList.add('badge')
      keywordBlock.classList.add('badge-danger')
      keywordForm.classList.add('text-white')
      keywordForm.innerText = '未設定'
    } else {
      keywordBlock.classList.remove('badge')
      keywordBlock.classList.remove('badge-danger')
      keywordForm.classList.remove('text-white')
      keywordForm.innerText = keyword
    }
  }

  return (
    <tbody className="table-striped">
      {articles.map((article) => (
        <tr key={article.id} data-id={article.id}>
          <td>
            <div className="form-check">
              <input
                className="form-check-input article-input"
                type="checkbox"
                id={article.id}
                onChange={() => toggleChecked(article)}
                checked={checked.includes(article)}
                defaultValue={article.title}
              />
            </div>
          </td>
          <td>
            <p className="d-block">
              <a target="blank" className="title" href={article.referer}>
                {article.title ? article.title : '-'}
              </a>
            </p>
            <div className="d-none">
              <div className="flex-textarea">
                <div aria-hidden="true" className="flex-textarea-dummy"></div>
                <textarea
                  name="title"
                  id="flex-textarea"
                  className="flex-textarea-textarea"
                  onChange={(e) => {
                    changeArticleState('title', e.target.value)
                  }}
                  defaultValue={article.title}
                ></textarea>
              </div>
            </div>
          </td>
          <td>
            {article.keyword ? (
              <div className="d-block">
                <a className="keyword" href={`/media/${mediaId}/articles/${article.id}`}>
                  {article.keyword}
                </a>
              </div>
            ) : (
              <div className="badge badge-danger d-block">
                <a className="keyword text-white" href={`/media/${mediaId}/articles/${article.id}`}>
                  未指定
                </a>
              </div>
            )}

            <p className="d-none">
              <input
                type="text"
                name="keyword"
                id="keyword"
                defaultValue={article.keyword}
                className="w-100"
                onChange={(e) => {
                  changeArticleState('keyword', e.target.value)
                }}
              />
            </p>
            {article.tags.map((tag, index) => (
              <a href={`/media/${article.medium_id}/tags/${tag.id}`} key={index}>
                <div className="badge badge-primary m-1">{tag.name}</div>
              </a>
            ))}
          </td>
          <td>{article.latest_rank}</td>
          <td>
            <p className="d-block goal_rank">{article.goal_rank}</p>
            <p className="d-none">
              <input
                type="number"
                name="goal_rank"
                id="goal_rank"
                min="0"
                defaultValue={article.goal_rank}
                className="w-100"
                onChange={(e) => {
                  changeArticleState('goal_rank', e.target.value)
                }}
              />
            </p>
          </td>
          <td>{article.rank_dif}</td>
          <td>{article.page_views_data ? article.page_views_data.toLocaleString() : '-'}</td>
          <td>
            <p className="d-block goal_pv">{article.goal_pv ? article.goal_pv.toLocaleString() : '-'}</p>
            <p className="d-none">
              <input
                type="number"
                name="goal_pv"
                id="goal_pv"
                className="w-100"
                defaultValue={article.goal_pv}
                onChange={(e) => {
                  changeArticleState('goal_pv', e.target.value)
                }}
              />
            </p>
          </td>
          <td>{(parseFloat(article.cvr) * 100).toFixed(2) + '%'}</td>
          <td>
            <p className="d-block goal_cvr">{article.goal_cvr ? (parseFloat(article.goal_cvr) * 100).toFixed(2) : 0.0}%</p>
            <p className="d-none">
              <input
                type="text"
                name="goal_cvr"
                id="goal_cvr"
                defaultValue={article.goal_cvr ? (parseFloat(article.goal_cvr) * 100).toFixed(2) : 0}
                className="w-100"
                onChange={(e) => {
                  changeArticleState('goal_cvr', e.target.value)
                }}
              />
            </p>
          </td>
          <td>{(parseFloat(article.cvr_dif) * 100).toFixed(2) + '%'}</td>
          <td>¥{article.average_profit && article.average_profit.toLocaleString()}</td>
          <td>
            <p className="d-block goal_price">¥{article.goal_price && article.goal_price.toLocaleString()}</p>
            <p className="d-none">
              <input
                type="number"
                name="goal_price"
                id="goal_price"
                className="w-100"
                defaultValue={article.goal_price}
                onChange={(e) => {
                  changeArticleState('goal_price', e.target.value)
                }}
              />
            </p>
          </td>
          <td>¥{article.profit && article.profit.toLocaleString()}</td>
          <td>¥{article.goal_profit && article.goal_profit.toLocaleString()}</td>
          <td>¥{article.profit_dif && article.profit_dif.toLocaleString()}</td>
          <td>{moment(article.published_at).format('Y年M月DD日')}</td>
          <td>{moment(article.modified_at).format('Y年M月DD日')}</td>
          <td className="button">
            <button
              type="submit"
              className="btn btn-primary quick-edit-button mb-2"
              data-id={article.id}
              // @ts-ignore
              onClick={(e) => editArticle(e.target, article)}
            >
              編集
            </button>
            <a className="btn btn-success mb-2" href={`/media/${article.medium_id}/tickets/new?article=${article.id}`}>
              +施策を追加
            </a>
            <button
              type="submit"
              className="btn btn-danger quick-delete-button mb-2"
              data-id={article.id}
              // @ts-ignore
              onClick={(e) => deleteArticle(e.target)}
            >
              削除
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default Record
