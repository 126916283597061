import axios from 'axios'
import React, { useState } from 'react'

const TicketTitle = ({ title: defaultTitle, ticket_id, medium_id }) => {
  const [ticketId, setTicketId] = useState(ticket_id)
  const [title, setTitle] = useState(defaultTitle)
  const [input, setInput] = useState(defaultTitle)
  const [editing, setEditing] = useState(false)

  const medium_ticket_url = `/media/${medium_id}/tickets/${ticketId}`

  const handleEditing = (e) => {
    e.preventDefault()
    setEditing(!editing)
  }

  const handleCancel = (e) => {
    e.preventDefault()
    setEditing(!editing)
    setInput(title)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') handleSubmit(e)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!input) return
    const API_ENDPOINT = `/api/v1/media/${medium_id}/tickets/${ticketId}`
    const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''
    axios
      .patch(API_ENDPOINT, {
        title: input,
        id: ticketId,
      }, {
        headers: { 'X-CSRF-TOKEN': csrfToken, 'Content-Type': 'application/json' }
      })
      .then((response) => {
        setTitle(input)
        setEditing(false)
      })
    setTitle(input)
    setEditing(!editing)
  }

  return (
    <div>
      <form>
        {editing ? (
          <div>
            <input
              className="form-control"
              value={input}
              onChange={(e) => {
                setInput(e.target.value)
              }}
              onKeyPress={handleKeyPress}
              type="text"
            />
            <div className="float-right">
              <button className="btn mr-2" onClick={handleCancel}>
                キャンセル
              </button>
              <button className="btn btn-primary" onClick={handleSubmit}>
                保存
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div className='justify-content-between d-flex'>
              <h2 className='w-50'>{title}</h2>
              <div>
                <button className="btn btn-secondary" onClick={handleEditing}>
                  <i className="fas fa-pen mr-2" />
                  タイトルを編集
                </button>
                <a href={medium_ticket_url} className="btn btn-danger ml-2" onClick={() => window.confirm('本当に削除しますか？')} rel="nofollow" data-method="delete">
                  施策を削除する
                </a>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

export default TicketTitle
