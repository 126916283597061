import React, { useState, useRef } from 'react'
import TagsModal from './TagsModal'
import Columns from '../../components/articles/Columns'
import Header from '../../components/articles/Header'
import Records from '../../components/articles/Records'
import { Article } from '../types/articles'

type Props = {
  articles: Article[]
  tagNames: {
    id: number
    name: string
  }[]
  mediaId: number
}

const Index: React.FC<Props> = (props) => {
  const [checked, setChecked] = useState<Article[]>([])
  const dateFields = useRef<HTMLInputElement>(null) as React.MutableRefObject<HTMLInputElement>

  return (
    <>
      <Header checked={checked} mediaId={props.mediaId} />

      <TagsModal
        titles={checked.map((article: Article) => article.title)}
        articleIds={checked.map((article: Article) => article.id)}
        tagNames={props.tagNames}
        isForAddingMultiTags={checked.length >= 0}
        mediumId={String(props.mediaId)}
      />

      <table
        // @ts-ignore
        ref={dateFields}
        className="table articles-index"
      >
        <Columns articles={props.articles} setChecked={setChecked} />
        <Records dateFields={dateFields} checked={checked} articles={props.articles} mediaId={props.mediaId} setChecked={setChecked} />
      </table>
    </>
  )
}

export default Index
