import axios from 'axios'
import React, { useState, useRef } from 'react'
// @ts-ignore
import { marked } from 'marked'
import Editor from './Editor'
import DOMPurify from 'dompurify'
import TicketTemplatesUseModal from './TicketTemplatesUseModal'
import { Ticket, User, Template } from '../types/types'
import { Article } from './types/articles'

type Props = {
  article: Article
  ticket: Ticket
  ticketUser: User
  ticketTemplates: Template[]
  toggleShouldShowMessageBeforeUnload: (isEditing: boolean) => void
}

const TicketDescription: React.FC<Props> = ({ article, ticket, ticketUser, ticketTemplates, toggleShouldShowMessageBeforeUnload }) => {
  const { description: defaultDescription, id: ticketId } = ticket
  let ticketUsername = ''
  let ticketUserThumbnail = { url: '' }
  if (ticketUser) {
    const { username, thumbnail } = ticketUser
    ticketUsername = username
    ticketUserThumbnail = thumbnail
  }
  let articleKeyword = ''
  if (article) {
    const { keyword } = article
    articleKeyword = keyword
  }

  const [input, setInput] = useState<string>(defaultDescription)
  const [description, setDescription] = useState<string>(defaultDescription)
  const [editing, setEditing] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isTemplatesUseModalOpen, setIsTemplatesUseModalOpen] = useState<boolean>(false)

  const IMAGE_SIZE_LIMIT = 10

  const submitButton = useRef(null)

  const handleDescriptionEditing = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    e.preventDefault()
    setEditing(true)
    toggleShouldShowMessageBeforeUnload(true)
    setInput(description)
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault()
    setEditing(false)
    toggleShouldShowMessageBeforeUnload(false)
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault()
    const url = `/api/v1/media/${ticket.medium_id}/tickets/${ticketId}`
    const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''
    if (!input) return

    axios
      .patch(
        url,
        {
          ticket: {
            id: ticketId,
            description: input,
            username: ticketUsername,
            keyword: articleKeyword,
          },
        },
        {
          headers: { 'X-CSRF-TOKEN': csrfToken, 'Content-Type': 'application/json' },
        },
      )
      .finally(() => {
        setDescription(input)
        setEditing(false)
        toggleShouldShowMessageBeforeUnload(false)
      })
  }

  const uploadImageToS3 = async (file: File): Promise<void> => {
    if (file.size > IMAGE_SIZE_LIMIT * 1024 * 1024) {
      setErrorMessage(`ファイルの上限サイズ${IMAGE_SIZE_LIMIT}MBを超えています`)
      return
    }

    try {
      const API_ENDPOINT = `/api/v1/ticket_comments/upload_image`
      const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''
      const formData = new FormData()
      formData.append('file', file)
      const imageUrl = await axios.post(API_ENDPOINT, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRF-TOKEN': csrfToken,
        },
      })
      return imageUrl.data.uploaded_file_full_path
    } catch (e) {
      alert('この形式のファイルはアップロードできません')
    }
  }

  const setTemplate = (templateDescription: Template['description']): void => {
    setInput(input + '  \n' + templateDescription)
  }

  const clickSubmitButton = (): void => {
    //@ts-ignore
    submitButton.current?.click()
  }

  return (
    <>
      <div className="row">
        <div className="col-md-1">
          {ticketUser && (
            <>
              <img src={ticketUserThumbnail?.url} alt="icon" className="rounded-circle mb-2" width="50" height="50" />
              <div className="small">{ticketUsername}</div>
            </>
          )}
        </div>
        <div className="col-md-11">
          <div className="ticket-description border mb-4">
            <div className="comment-header border-bottom p-2 bg-light" style={{ minHeight: '50px' }}>
              {ticketUser && <span className="p-2">{ticketUsername}さんが作成しました</span>}
              <span className="dropdown float-right">
                <button
                  className="btn"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-bars mr-2" />
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" id="dropdownMenuButton">
                  <span className="dropdown-item" onClick={handleDescriptionEditing}>
                    <i className="fas fa-pen mr-2" />
                    <span>編集</span>
                  </span>
                </div>
              </span>
            </div>
            {editing ? (
              <div>
                <div className="comment-body align-items-baseline pt-2">
                  <div className="text-end mb-2">
                    <div
                      className="btn btn-outline-primary"
                      onClick={() => {
                        setIsTemplatesUseModalOpen(true)
                      }}
                    >
                      <i className="fa fa-plus mr-2" />
                      <span data-toggle="modal" data-target="#ticket_templates_use_modal">
                        テンプレートを利用する
                      </span>
                    </div>
                  </div>
                  <Editor
                    value={input}
                    onChange={(data) => {
                      setInput(data.text)
                    }}
                    onImageUpload={uploadImageToS3}
                    submit={clickSubmitButton}
                  />
                  {!!errorMessage && <p className="text-danger">{errorMessage}</p>}
                </div>
                <div className="comment-footer comment-footer bg-light p-2 border">
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-secondary mr-2" onClick={handleCancel}>
                      キャンセル
                    </button>
                    <button className="btn btn-primary" onClick={handleSubmit} ref={submitButton}>
                      更新
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="comment-body markdown-body">
                  <div className="p-4 comment-body-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(description)) }} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isTemplatesUseModalOpen && <TicketTemplatesUseModal defaultTicketTemplates={ticketTemplates} setTemplate={setTemplate} />}
    </>
  )
}

export default TicketDescription
