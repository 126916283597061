import React from 'react'
import { HasPromotionTagsFilterValue } from '../../types/promotions'

type Props = {
  promotionNameFilterValue: string
  hasPromotionTagsFilterValue: HasPromotionTagsFilterValue
  setPromotionNameFilterValue: React.Dispatch<React.SetStateAction<string>>
  setHasPromotionTagsFilterValue: React.Dispatch<React.SetStateAction<HasPromotionTagsFilterValue>>
}

const PromotionsIndexFilter: React.FC<Props> = ({
  promotionNameFilterValue,
  hasPromotionTagsFilterValue,
  setPromotionNameFilterValue,
  setHasPromotionTagsFilterValue,
}) => {
  const hasPromotionTagsFilterOptions: { id: HasPromotionTagsFilterValue; value: HasPromotionTagsFilterValue; label: string }[] = [
    {
      id: 'all',
      value: 'all',
      label: '全て',
    },
    {
      id: 'hasPromotionTags',
      value: 'hasPromotionTags',
      label: 'プロモーションタグ設定済みのみ',
    },
    {
      id: 'notHasPromotionTags',
      value: 'notHasPromotionTags',
      label: 'プロモーションタグ未設定のみ',
    },
  ]

  return (
    <>
      <input
        placeholder="プロモーション名を入力して検索"
        value={promotionNameFilterValue}
        onChange={(e) => setPromotionNameFilterValue(e.target.value)}
        className="form-control mb-3"
      />
      <div className="d-flex ml-2">
        {hasPromotionTagsFilterOptions.map((option) => (
          <div key={option.value} className="mr-3">
            <input
              type="radio"
              checked={hasPromotionTagsFilterValue === option.value}
              onChange={() => setHasPromotionTagsFilterValue(option.value)}
              id={option.id}
              value={option.value}
              key={option.value}
            />
            <label htmlFor={option.id} className="pl-2">
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </>
  )
}

export default PromotionsIndexFilter
