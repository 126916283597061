import React, { useEffect, useCallback } from 'react'
import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css'

// @ts-ignore
import { marked } from 'marked'

type Props = {
  value: string
  placeholder?: string
  onChange?: (
    data: {
      text: string
      html: string
    },
    e?: React.ChangeEvent<HTMLTextAreaElement>,
  ) => void
  onImageUpload?: (file: File) => void
  submit: () => void
}

const EditorComponent: React.FC<Props> = ({ value, placeholder, onChange, onImageUpload, submit = () => {} }) => {
  const submitWithShiftAndEnter = useCallback((e: KeyboardEvent) => {
    if (e.code === 'Enter' && e.shiftKey) {
      e.preventDefault()
      submit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addKeydownEvent = (): void => {
    window.addEventListener('keydown', submitWithShiftAndEnter)
  }

  const removeKeydownEvent = (): void => {
    window.removeEventListener('keydown', submitWithShiftAndEnter)
  }

  useEffect(() => {
    return () => window.removeEventListener('keydown', submitWithShiftAndEnter)
  }, [submitWithShiftAndEnter])

  return (
    <MdEditor
      value={value}
      renderHTML={(text) => marked(text)}
      onChange={onChange}
      onImageUpload={onImageUpload}
      imageAccept=".jpg,.png"
      style={{ height: '500px' }}
      markdownClass="border-right border-dark"
      placeholder={placeholder}
      onFocus={addKeydownEvent}
      onBlur={removeKeydownEvent}
    />
  )
}
export default EditorComponent
