import axios from 'axios'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import Editor from './Editor'
import TicketAssignedUsers from './TicketAssignedUsers'
import TicketTemplatesUseModal from './TicketTemplatesUseModal'
import { Template } from '../types/types'
import { TicketNewState } from './types/ticket'

type Props = {
  current_user: { id: number }
  current_medium: { id: number }
  priorities: { [key: string]: string }
  keywords: string[]
  usernames: string[]
}

const TicketsNew: React.FC<Props> = ({ current_user, current_medium, usernames, keywords}) => {
  const initialTicketState: TicketNewState = {
    title: '',
    description: '',
    priority: '',
    status: '',
    genre: '',
    due_date: '',
    validate_start_at: '',
    validate_end_at: '',
    keyword: '',
    notion_url: '',
    username: '',
    usernames: [],
    roles: [],
    workload: '',
    estimated_workload: '',
  }
  const [ticketState, setTicketState] = useState<TicketNewState>(initialTicketState)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [ticketTemplates, setTicketTemplates] = useState<Template[]>([])

  const IMAGE_SIZE_LIMIT = 10
  const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''

  const submitButton = useRef(null)

  const changeTicketState = (key: string, value: string | string[]): void => {
    setTicketState({ ...ticketState, [key]: value })
  }

  const submitTicket = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    window.removeEventListener('beforeunload', showMessageBeforeUnload)
    e.preventDefault()
    const url = `/api/v1/media/${current_medium.id}/tickets`
    const {
      title,
      description,
      priority,
      status,
      genre,
      username,
      due_date,
      validate_start_at,
      validate_end_at,
      keyword,
      notion_url,
      usernames,
      roles,
      estimated_workload,
      workload,
    } = ticketState
    if (!title) {
      alert('施策名を入力してください')
      return
    }
    const params = {
      title: title,
      description: description,
      priority: parseInt(priority),
      status: status,
      genre: genre,
      username: username,
      usernames: usernames,
      roles: roles,
      due_date: due_date,
      validate_start_at: validate_start_at,
      validate_end_at: validate_end_at,
      keyword: keyword,
      notion_url: notion_url,
      user_id: current_user.id,
      estimated_workload: estimated_workload,
      workload: workload,
    }
    axios
      .post(url, { ticket: params }, { headers: { 'X-CSRF-TOKEN': csrfToken, 'Content-Type': 'application/json' } })
      .then((response) => {
        setTicketState(initialTicketState)
        const event = new CustomEvent("modal:close", { bubbles: true });
        document.dispatchEvent(event);
        // FIXME:施策作成ページはいずれ削除の予定なので、即時対応としてlocation.hrefでリダイレクトさせています。
        // https://github.com/indieverse-jp/ASP-ADMIN/issues/4955
        if (location.pathname.includes('new')){
          alert('施策を追加しました')
          location.href = response.data.url
        }
      })
      .catch((error) => {
        alert(`施策を追加できませんでした。(${error.response.data.message})`)
      })
  }

  const uploadImageToS3 = async (file: File): Promise<void> => {
    if (file.size > IMAGE_SIZE_LIMIT * 1024 * 1024) {
      setErrorMessage(`ファイルの上限サイズ${IMAGE_SIZE_LIMIT}MBを超えています`)
      return
    }
    try {
      const API_ENDPOINT = `/api/v1/ticket_comments/upload_image`
      const formData = new FormData()
      formData.append('file', file)
      const imageUrl = await axios.post(API_ENDPOINT, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRF-TOKEN': csrfToken,
        },
      })
      return imageUrl.data.uploaded_file_full_path
    } catch (e) {
      alert('画像のアップロードに失敗しました')
    }
  }

  const setTemplate = (templateDescription: Template['description']): void => {
    const newDescription = ticketState.description + '  \n' + templateDescription
    setTicketState({ ...ticketState, description: newDescription })
  }

  const showMessageBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (ticketState.title || ticketState.description) {
        event.preventDefault()
        event.returnValue = ''
      }
    },
    [ticketState.title, ticketState.description],
  )

  const clickSubmitButton = (): void => {
    //@ts-ignore
    submitButton.current?.click()
  }

  useEffect(() => {
    const fetchTicketTemplates = async (): Promise<void> => {
      const url = `/api/v1/media/${current_medium.id}/ticket_templates/`
      await axios.get(url).then((response) => {
        setTicketTemplates(response.data.ticket_templates)
      })
    }
    fetchTicketTemplates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', showMessageBeforeUnload)
    return () => window.removeEventListener('beforeunload', showMessageBeforeUnload)
  }, [showMessageBeforeUnload])

  return (
    <>
      <div className="tab-content">
        <div id="ticket-new" className="tab-pane fade show active" aria-labelledby="monthly-tab" role="tabpanel">
          <div className="form-group">
            <textarea
              className="form-control"
              placeholder="施策タイトル"
              name="ticket[title]"
              id="ticket_title"
              spellCheck="false"
              value={ticketState.title}
              onChange={(e) => {
                changeTicketState('title', e.target.value)
              }}
            />
          </div>
          <div className="row">
            <div className="col-md-10">
              <div className="form-group mb-4">
                <div className="mb-4">
                  <label htmlFor="ticket_施策概要">施策概要</label>
                  <div className="btn btn-secondary float-right">
                    <i className="fa fa-plus mr-2" />
                    <span data-toggle="modal" data-target="#ticket_templates_use_modal">
                      テンプレートを利用する
                    </span>
                  </div>
                </div>
                <Editor
                  value={ticketState.description}
                  onChange={(data) => {
                    changeTicketState('description', data.text)
                  }}
                  onImageUpload={uploadImageToS3}
                  placeholder="具体的な施策概要を入力してください"
                  submit={clickSubmitButton}
                />
                {!!errorMessage && <p className="text-danger">{errorMessage}</p>}
              </div>
              <div className="form-group">
                <a href="#" className="form-control btn btn-primary" onClick={submitTicket} ref={submitButton}>
                  施策を追加する
                </a>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="ticket_priority">優先度</label>
                <select
                  className="form-control"
                  name="ticket[priority]"
                  id="ticket_priority"
                  value={ticketState.priority}
                  onChange={(e) => {
                    changeTicketState('priority', e.target.value)
                  }}
                >
                  <option>---選択してください---</option>
                  <option value="0">低</option>
                  <option value="1">中</option>
                  <option value="2">高</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="ticket_status">ステータス</label>
                <select
                  className="form-control"
                  name="ticket[status]"
                  id="ticket_status"
                  value={ticketState.status}
                  onChange={(e) => {
                    changeTicketState('status', e.target.value)
                  }}
                >
                  <option>---選択してください---</option>
                  <option value="未着手">未着手</option>
                  <option value="進行中">進行中</option>
                  <option value="検証中">検証中</option>
                  <option value="完了">完了</option>
                  <option value="成功">成功</option>
                  <option value="失敗">失敗</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="ticket_genre">施策種別</label>
                <select
                  className="form-control"
                  name="ticket[genre]"
                  id="ticket_genre"
                  value={ticketState.genre}
                  onChange={(e) => {
                    changeTicketState('genre', e.target.value)
                  }}
                >
                  <option>---選択してください---</option>
                  <option value="収穫">収穫</option>
                  <option value="水やり">水やり</option>
                  <option value="種まき">種まき</option>
                </select>
              </div>
              <TicketAssignedUsers
                initialAssignedUsers={[]}
                usernames={usernames}
                mediumId={current_medium.id}
                ticketState={ticketState}
                setTicketState={setTicketState}
              />
              <input type="hidden" id="ticket_user_id" name="ticket[user_id]" value={current_user.id} />
              <input type="hidden" id="ticket_usernames" name="ticket[usernames]" value={ticketState.usernames} />
              <input type="hidden" id="ticket_roles" name="ticket[roles]" value={ticketState.roles} />
              <div className="form-group">
                <label htmlFor="ticket_due_date">実施期限</label>
                <input
                  className="form-control"
                  type="date"
                  name="ticket[due_date]"
                  id="ticket_due_date"
                  value={ticketState.due_date}
                  onChange={(e) => {
                    changeTicketState('due_date', e.target.value)
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="ticket_validate_start_at">検証開始日</label>
                <input
                  className="form-control"
                  type="date"
                  name="ticket[validate_start_at]"
                  id="ticket_validate_start_at"
                  value={ticketState.validate_start_at}
                  onChange={(e) => {
                    changeTicketState('validate_start_at', e.target.value)
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="ticket_validate_end_at">検証終了日</label>
                <input
                  className="form-control"
                  type="date"
                  name="ticket[validate_end_at]"
                  id="ticket_validate_end_at"
                  value={ticketState.validate_end_at}
                  onChange={(e) => {
                    changeTicketState('validate_end_at', e.target.value)
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="ticket_keyword">記事</label>
                <input
                  className="form-control"
                  list="keyword-list"
                  type="text"
                  name="ticket[keyword]"
                  id="ticket_keyword"
                  value={ticketState.keyword}
                  onChange={(e) => {
                    changeTicketState('keyword', e.target.value)
                  }}
                />
                <datalist id="keyword-list">
                  {keywords.map((keywords, index) => (
                    <option value={keywords} key={index}></option>
                  ))}
                </datalist>
              </div>
              <div className="form-group">
                <label htmlFor="ticket_notion_url">Notion url</label>
                <input
                  className="form-control"
                  type="text"
                  name="ticket[notion_url]"
                  id="ticket_notion_url"
                  value={ticketState.notion_url}
                  onChange={(e) => {
                    changeTicketState('notion_url', e.target.value)
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="ticket_estimated_workload">見積もり工数(分)</label>
                <input
                  className="form-control"
                  type="text"
                  name="ticket[estimated_workload]"
                  id="ticket_estimated_workload"
                  value={ticketState.estimated_workload}
                  onChange={(e) => {
                    changeTicketState('estimated_workload', e.target.value)
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="ticket_workload">実工数(分)</label>
                <input
                  className="form-control"
                  type="text"
                  name="ticket[workload]"
                  id="ticket_workload"
                  value={ticketState.workload}
                  onChange={(e) => {
                    changeTicketState('workload', e.target.value)
                  }}
                />
              </div>
              </div>
            </div>
          </div>
        </div>
        <div id="ticket-analysis" className="tab-pane fade show active" aria-labelledby="analytis-tab" role="tabpanel">
          <div className="alert alert-info">記事を紐づけると、データの分析をおこないます</div>
        </div>
      <TicketTemplatesUseModal defaultTicketTemplates={ticketTemplates} setTemplate={setTemplate} />
    </>
  )
}

export default TicketsNew
