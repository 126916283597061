import React, { useState, useRef } from 'react'
import axios from 'axios'
import { Template } from '../../types/types'
import Editor from '../Editor'

type Props = {
  defaultTemplate: Template
  currentMedium: {
    id: string
  }
}

const TicketTemplatesDetail: React.FC<Props> = ({ defaultTemplate, currentMedium }) => {
  const [template, setTemplate] = useState<Template>(defaultTemplate)
  const IMAGE_SIZE_LIMIT = 10
  const [errorMessage, setErrorMessage] = useState<string>('')

  const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''
  const submitButton = useRef(null)

  const editTemplate = (): void => {
    const { title, description } = template

    if (!title || !description) {
      alert('タイトルと概要を入力してください')
      return
    }

    const url = `/api/v1/media/${currentMedium.id}/ticket_templates/${template.id}`
    const params = {
      ticket_template: {
        title: title,
        description: description,
      },
    }
    axios.patch(url, params, { headers: { 'X-CSRF-TOKEN': csrfToken, 'Content-Type': 'application/json' } }).then(() => {
      alert('施策テンプレートを更新しました')
      location.reload()
    })
  }

  const uploadImageToS3 = async (file: File): Promise<void> => {
    if (file.size > IMAGE_SIZE_LIMIT * 1024 * 1024) {
      setErrorMessage(`ファイルの上限サイズ${IMAGE_SIZE_LIMIT}MBを超えています`)
      return
    }
    try {
      const API_ENDPOINT = `/api/v1/ticket_comments/upload_image`
      const formData = new FormData()
      formData.append('file', file)
      const imageUrl = await axios.post(API_ENDPOINT, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return imageUrl.data.uploaded_file_full_path
    } catch (e) {
      alert('画像のアップロードに失敗しました')
    }
  }

  const clickSubmitButton = (): void => {
    //@ts-ignore
    submitButton.current?.click()
  }

  const deleteTemplate = (): void => {
    alert('施策テンプレートを削除しますか？')
    const url = `/api/v1/media/${template.medium_id}/ticket_templates/${template.id}`
    axios
      .delete(url, {
        headers: {
          'X-CSRF-TOKEN': csrfToken,
          'Content-Type': 'application/json',
        }
      })
      .then(() => {
        alert('施策テンプレートを削除しました')
        window.location.href = `/media/${currentMedium.id}/ticket_templates`
      })
  }

  return (
    <>
      <h3 className="mb-5">施策テンプレート編集</h3>
      <div className="mb-5">
        <h5 className="mb-2">タイトル</h5>
        <input
          value={template.title}
          onChange={(e) => {
            setTemplate({ ...template, title: e.target.value })
          }}
          placeholder="施策テンプレート名を入力してください"
          className="col-md-12"
        />
      </div>
      <div className="mb-5">
        <h5 className="mb-2">概要</h5>
        <Editor
          value={template.description}
          onChange={(data) => {
            setTemplate({ ...template, description: data.text })
          }}
          onImageUpload={uploadImageToS3}
          submit={clickSubmitButton}
        />
        {!!errorMessage && <p className="text-danger">{errorMessage}</p>}
      </div>
      <button className="btn btn-primary col-md-12 mb-4" onClick={editTemplate} ref={submitButton}>
        更新
      </button>
      <button className="btn btn-danger col-md-12" onClick={deleteTemplate}>
        削除
      </button>
    </>
  )
}

export default TicketTemplatesDetail
