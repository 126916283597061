import axios from 'axios'
import React, { useState, useRef, useEffect } from 'react'
// @ts-ignore
import { marked } from 'marked'
import moment from 'moment'
import Editor from './Editor'
import DOMPurify from 'dompurify'
import TicketTemplatesUseModal from './TicketTemplatesUseModal'
import { TicketComment as TicketCommentType, User, Template } from '../types/types'

type Props = {
  ticketComment: TicketCommentType
  ticketCommentUserId: number
  currentUser: User
  ticketTemplates: Template[]
  toggleShouldShowMessageBeforeUnload: (isEditing: boolean) => void
}

const TicketComment: React.FC<Props> = ({
  ticketComment,
  ticketCommentUserId,
  currentUser,
  ticketTemplates,
  toggleShouldShowMessageBeforeUnload,
}) => {
  const { description: defaultDescription, id: ticketCommentId, created_at } = ticketComment
  const [ticketCommentUser, setTicketCommentUser] = useState<User>()
  const { id: currentUserId } = currentUser

  const [input, setInput] = useState<string>(defaultDescription)
  const [description, setDescription] = useState<string>(defaultDescription)
  const [editing, setEditing] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isTemplatesUseModalOpen, setIsTemplatesUseModalOpen] = useState<boolean>(false)
  const isUrlHashTargetComment = window.location.hash.slice(1) === `ticket_comment_${ticketCommentId}`
  const IMAGE_SIZE_LIMIT = 10

  const submitButton = useRef(null)

  const handleEditing = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    e.preventDefault()
    setEditing(true)
    toggleShouldShowMessageBeforeUnload(true)
    setInput(description)
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault()
    setEditing(false)
    toggleShouldShowMessageBeforeUnload(false)
    setInput('')
  }

  const copyLink = (): void => {
    const link = `${window.location.href}/#ticket_comment_${ticketCommentId}`
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert('URLをコピーしました')
      })
      .catch(() => {
        alert('URLをコピーできませんでした')
      })
  }

  const handleDelete = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    e.preventDefault()
    if (!confirm('削除しますか？')) return
    const API_ENDPOINT = `/api/v1/ticket_comments/${ticketCommentId}`
    const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''
    axios
      .delete(API_ENDPOINT, {
        headers: { 'X-CSRF-TOKEN': csrfToken, 'Content-Type': 'application/json' },
        data: {
          id: ticketCommentId,
        },
      })
      .then(() => {
        alert('削除しました')
        toggleShouldShowMessageBeforeUnload(false)
        location.reload()
      })
  }

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault()
    if (!input) return
    const API_ENDPOINT = `/api/v1/ticket_comments/${ticketCommentId}`
    const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''
    axios
      .patch(
        API_ENDPOINT,
        {
          description: input,
          id: ticketCommentId,
        },
        {
          headers: { 'X-CSRF-TOKEN': csrfToken, 'Content-Type': 'application/json' },
        },
      )
      .then(() => {
        setDescription(input)
        setEditing(false)
        toggleShouldShowMessageBeforeUnload(false)
      })
  }

  const uploadImageToS3 = async (file: File): Promise<void> => {
    if (file.size > IMAGE_SIZE_LIMIT * 1024 * 1024) {
      setErrorMessage(`ファイルの上限サイズ${IMAGE_SIZE_LIMIT}MBを超えています`)
      return
    }
    try {
      const API_ENDPOINT = '/api/v1/ticket_comments/upload_image'
      const csrfToken = document.querySelector('[name=csrf-token]')?.getAttribute('content') || ''
      const formData = new FormData()
      formData.append('file', file)
      const imageUrl = await axios.post(API_ENDPOINT, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRF-TOKEN': csrfToken,
        },
      })
      return imageUrl.data.uploaded_file_full_path
    } catch (e) {
      console.log(e)
    }
  }

  const setTemplate = (templateDescription: Template['description']): void => {
    setInput(input + '  \n' + templateDescription)
  }

  const clickSubmitButton = (): void => {
    //@ts-ignore
    submitButton.current?.click()
  }

  useEffect(() => {
    const fetchTicketCommentUser = async (): Promise<void> => {
      const API_ENDPOINT = `/api/v1/users/${ticketCommentUserId}`
      await axios.get(API_ENDPOINT).then((response) => {
        setTicketCommentUser(response.data.user)
      })
    }
    fetchTicketCommentUser()
  }, [ticketCommentUserId])

  return (
    <div id={`ticket_comment_${ticketCommentId}`}>
      <div className="row mb-4">
        <div className="col-md-1">
          <img src={ticketCommentUser?.thumbnail.url} alt="icon" className="rounded-circle mb-2" width="50" height="50" />
          <div className="small">{ticketCommentUser?.username}</div>
        </div>
        <div className="col-md-11">
          <div className={`border${isUrlHashTargetComment ? ' border-primary' : ''}`}>
            <div className="comment-header border-bottom p-2 bg-light">
              <span className="p-2">
                {ticketCommentUser?.username}さんが{moment(created_at).format('YYYY-MM-DD hh:mm')}にコメントしました
              </span>
              <div className="dropdown float-right">
                <button
                  className="btn"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-bars mr-2" />
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  {ticketCommentUserId === currentUserId && (
                    <>
                      <span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={handleEditing}>
                        <i className="fas fa-pen mr-2" />
                        編集
                      </span>
                      <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={handleDelete}>
                        <i className="fas fa-trash-alt mr-2" />
                        削除
                      </a>
                    </>
                  )}
                  <span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={copyLink}>
                    <i className="fas fa-copy mr-2" />
                    URLをコピー
                  </span>
                </div>
              </div>
            </div>
            <div className="comment-body-text markdown-body">
              {editing ? (
                <div>
                  <div className="pt-2">
                    <div className="text-end mb-2">
                      <div
                        className="btn btn-outline-primary"
                        onClick={() => {
                          setIsTemplatesUseModalOpen(true)
                        }}
                      >
                        <i className="fa fa-plus mr-2" />
                        <span data-toggle="modal" data-target="#ticket_templates_use_modal">
                          テンプレートを利用する
                        </span>
                      </div>
                    </div>
                    <Editor
                      value={input}
                      onChange={(data) => {
                        setInput(data.text)
                      }}
                      onImageUpload={uploadImageToS3}
                      submit={clickSubmitButton}
                    />
                    {!!errorMessage && <p className="text-danger">{errorMessage}</p>}
                  </div>
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-secondary mr-2" onClick={handleCancel}>
                      キャンセル
                    </button>
                    <button className="btn btn-primary" onClick={handleSubmit} ref={submitButton}>
                      更新
                    </button>
                  </div>
                </div>
              ) : (
                <div className="comment-body p-4">
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(description)) }} className="comment-body-content" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isTemplatesUseModalOpen && <TicketTemplatesUseModal defaultTicketTemplates={ticketTemplates} setTemplate={setTemplate} />}
    </div>
  )
}
export default TicketComment
