import React, { useState } from 'react'

type Props = {
  filterTemplates: (searchInput: string) => void
}

const TicketTemplatesSearchInput: React.FC<Props> = ({ filterTemplates }) => {
  const [inputValue, setInputValue] = useState<string>('')

  return (
    <input
      placeholder="施策テンプレート名を入力して検索"
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.target.value)
        filterTemplates(e.target.value)
      }}
      className="form-control mb-5"
    />
  )
}
export default TicketTemplatesSearchInput
