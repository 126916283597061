import React, { useState, useEffect, useCallback } from 'react'
import TicketDescription from './TicketDescription'
import TicketComment from './TicketComment'
import TicketNewComment from './TicketNewComment'
import ImageExpandModal from './ImageExpandModal'
import { Ticket, User, Template, TicketComment as TicketCommentType } from '../types/types'
import { Article } from './types/articles'

type Props = {
  article: Article
  ticket: Ticket
  ticketUser: User
  ticketTemplates: Template[]
  ticketComments: TicketCommentType[]
  currentUser: User
}

const TicketDescriptionAndComment: React.FC<Props> = ({ article, ticket, ticketUser, ticketTemplates, ticketComments, currentUser }) => {
  const [imageUrlForExpand, setImageUrlForExpand] = useState<string>('')
  const [shouldShowMessageBeforeUnload, setShouldShowMessageBeforeUnload] = useState<boolean>(false)

  const toggleShouldShowMessageBeforeUnload = (shouldShowMessageBeforeUnload: boolean): void => {
    setShouldShowMessageBeforeUnload(shouldShowMessageBeforeUnload)
  }

  const showMessageBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!shouldShowMessageBeforeUnload) return
      event.preventDefault()
      event.returnValue = ''
    },
    [shouldShowMessageBeforeUnload],
  )

  useEffect(() => {
    // description, comment内の画像をクリックした時に拡大表示モーダルを表示するように
    const images = document.querySelectorAll('.comment-body-content p img') as NodeListOf<HTMLImageElement>
    if (images.length) {
      Array.from(images).forEach((image) => {
        image.style.cursor = 'pointer'
        image.setAttribute('data-toggle', 'modal')
        image.setAttribute('data-target', '#image_expand_modal')
        image.addEventListener('click', () => {
          setImageUrlForExpand(image.src)
        })
      })
    }
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', showMessageBeforeUnload)
    return () => window.removeEventListener('beforeunload', showMessageBeforeUnload)
  }, [showMessageBeforeUnload])

  return (
    <>
      <TicketDescription
        article={article}
        ticket={ticket}
        ticketUser={ticketUser}
        ticketTemplates={ticketTemplates}
        toggleShouldShowMessageBeforeUnload={toggleShouldShowMessageBeforeUnload}
      />
      <div className="comment-container">
        {ticketComments.map((comment) => (
          <TicketComment
            ticketComment={comment}
            ticketCommentUserId={comment.user_id}
            currentUser={currentUser}
            ticketTemplates={ticketTemplates}
            toggleShouldShowMessageBeforeUnload={toggleShouldShowMessageBeforeUnload}
            key={comment.id}
          />
        ))}
      </div>
      <div className="comment-new">
        <TicketNewComment
          ticket={ticket}
          currentUser={currentUser}
          ticketTemplates={ticketTemplates}
          toggleShouldShowMessageBeforeUnload={toggleShouldShowMessageBeforeUnload}
        />
      </div>

      {!!imageUrlForExpand && <ImageExpandModal imageUrl={imageUrlForExpand} />}
    </>
  )
}
export default TicketDescriptionAndComment
